define("ember-models-table/themes/ember-bootstrap-v3", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-models-table/themes/bootstrap3"], function (_exports, _defineProperty2, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class EmberBootstrap3Theme
   * @namespace Themes
   * @extends Themes.Bootstrap3Theme
   */
  class EmberBootstrap3Theme extends _bootstrap.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "componentsPath", 'models-table/themes/ember-bootstrap-v3/');
    }

  }

  _exports.default = EmberBootstrap3Theme;
});