define("ember-models-table/templates/components/models-table/themes/bootstrap3/row-select-all-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7I4TwJ7u",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"button\",true],[13,\"onclick\",[27,[24,0],[\"doToggleAllSelection\"]],null],[13,\"class\",[31,77,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonLink\"]],\" toggle-all\"],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[31,141,3,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[\"selectedItems\",\"length\"]],[31,172,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"selectedItems\",\"length\"]],[27,[24,0],[\"data\",\"length\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"span\",true],[12,\"class\",\"emt-icons-stack\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"i\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"selectSomeRowsIcon\"]],null],[10],[11],[1,1,0,0,\"\\n      \"],[9,\"i\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"deselectAllRowsIcon\"]],null],[10],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n     \\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"i\",true],[13,\"class\",[31,442,2,[27,[26,1,\"CallHead\"],[]],[[31,453,8,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"selectedItems\",\"length\"]],[27,[24,0],[\"data\",\"length\"]]],null],[27,[24,0],[\"themeInstance\",\"selectAllRowsIcon\"]],[27,[24,0],[\"themeInstance\",\"deselectAllRowsIcon\"]]],null],null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"],[16,1,null]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"if\",\"concat\",\"not-eq\",\"and\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/bootstrap3/row-select-all-checkbox.hbs"
    }
  });

  _exports.default = _default;
});