define("ember-models-table/themes/ember-paper", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-models-table/themes/default"], function (_exports, _defineProperty2, _default) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class EmberPaperTheme
   * @namespace Themes
   * @extends Themes.DefaultTheme
   */
  class EmberPaperTheme extends _default.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "componentsPath", 'models-table/themes/ember-paper/');
      (0, _defineProperty2.default)(this, "table", 'paper-table');
      (0, _defineProperty2.default)(this, "headerWrapper", 'layout-row layout-align-space-between');
      (0, _defineProperty2.default)(this, "tfooterInternalWrapper", 'layout-row layout-align-space-between-center footer-internal-wrapper');
      (0, _defineProperty2.default)(this, "paginationInternalWrapper", 'layout-row layout-align-space-between-center');
      (0, _defineProperty2.default)(this, "columnVisibleIcon", 'check_box');
      (0, _defineProperty2.default)(this, "columnHiddenIcon", 'check_box_outline_blank');
      (0, _defineProperty2.default)(this, "sortAscIcon", 'arrow_drop_up');
      (0, _defineProperty2.default)(this, "sortDescIcon", 'arrow_drop_down');
      (0, _defineProperty2.default)(this, "navFirstIcon", 'first_page');
      (0, _defineProperty2.default)(this, "navPrevIcon", 'chevron_left');
      (0, _defineProperty2.default)(this, "navNextIcon", 'chevron_right');
      (0, _defineProperty2.default)(this, "navLastIcon", 'last_page');
      (0, _defineProperty2.default)(this, "clearAllFiltersIcon", 'clear');
      (0, _defineProperty2.default)(this, "filteringCellInternalWrapper", 'layout-row layout-align-space-between-center');
      (0, _defineProperty2.default)(this, "columnsDropdownWrapper", 'columns-dropdown');
      (0, _defineProperty2.default)(this, "collapseRowIcon", 'expand_less');
      (0, _defineProperty2.default)(this, "expandRowIcon", 'expand_more');
      (0, _defineProperty2.default)(this, "collapseAllRowsIcon", 'expand_less');
      (0, _defineProperty2.default)(this, "expandAllRowsIcon", 'expand_more');
    }

  }

  _exports.default = EmberPaperTheme;
});