define("ember-models-table/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqual = isEqual;

  function isEqual(_ref
  /*, hash*/
  ) {
    let [left, right, type] = _ref;

    if (type === 'number') {
      return Number(left) === Number(right);
    }

    return left === right;
  }

  var _default = Ember.Helper.helper(isEqual);

  _exports.default = _default;
});