define("ember-models-table/components/models-table/themes/bootstrap4/global-filter", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/themes/bootstrap4/global-filter", "ember-models-table/components/models-table/global-filter"], function (_exports, _component, _globalFilter, _globalFilter2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class Bs4ModelsTableGlobalFilter
   * @namespace Components
   * @extends Components.ModelsTableGlobalFilter
   */
  let Bs4ModelsTableGlobalFilterComponent = (_dec = (0, _component.layout)(_globalFilter.default), _dec(_class = class Bs4ModelsTableGlobalFilterComponent extends _globalFilter2.default {}) || _class);
  _exports.default = Bs4ModelsTableGlobalFilterComponent;
});