define("ember-cli-clipboard/templates/components/copy-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OZQr4/dF",
    "block": "{\"symbols\":[\"@aria-label\",\"@classNames\",\"@class\",\"@disabled\",\"@title\",\"&attrs\",\"@clipboardText\",\"@clipboardTarget\",\"@clipboardAction\",\"@buttonType\",\"&default\"],\"statements\":[[9,\"button\",false],[14,\"aria-label\",[27,[24,1],[]],null],[14,\"class\",[32,[\"copy-btn \",[27,[24,3],[]],\" \",[27,[24,2],[]]]],null],[14,\"disabled\",[27,[24,4],[]],null],[14,\"title\",[27,[24,5],[]],null],[15,6],[14,\"data-clipboard-text\",[27,[24,7],[]],null],[14,\"data-clipboard-target\",[27,[24,8],[]],null],[14,\"data-clipboard-action\",[27,[24,9],[]],null],[14,\"type\",[31,136,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,10],[]],[27,[24,10],[]],\"button\"],null],null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[\"setupElement\"]]],null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[\"registerClipboard\"]]],null],[3,0,0,[27,[26,2,\"ModifierHead\"],[]],[[27,[24,0],[\"registerClipboard\"]]],null],[3,0,0,[27,[26,3,\"ModifierHead\"],[]],[[27,[24,0],[\"destroyClipboard\"]]],null],[10],[1,1,0,0,\"\\n  \"],[16,11,null],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"did-update\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "ember-cli-clipboard/templates/components/copy-button.hbs"
    }
  });

  _exports.default = _default;
});