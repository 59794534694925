define("ember-models-table/templates/components/models-table/row-select-all-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f/GYIHQj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"button\",true],[13,\"onclick\",[27,[24,0],[\"doToggleAllSelection\"]],null],[13,\"class\",[31,77,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonLink\"]],\" toggle-all\"],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",true],[13,\"class\",[31,145,2,[27,[26,1,\"CallHead\"],[]],[[31,154,8,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"selectedItems\",\"length\"]],[27,[24,0],[\"data\",\"length\"]]],null],[27,[24,0],[\"themeInstance\",\"selectAllRowsIcon\"]],[31,255,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"selectedItems\",\"length\"]],[27,[24,0],[\"themeInstance\",\"selectSomeRowsIcon\"]],[27,[24,0],[\"themeInstance\",\"deselectAllRowsIcon\"]]],null]],null],null],[10],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[16,1,null]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"is-equal\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/row-select-all-checkbox.hbs"
    }
  });

  _exports.default = _default;
});