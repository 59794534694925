define("ember-models-table/templates/components/models-table/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dH+TUbmZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[13,\"class\",[31,59,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"form\"]],\" globalSearch\"],null],null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"formElementWrapper\"]],null],[10],[1,1,0,0,\"\\n      \"],[9,\"label\",true],[13,\"for\",[27,[24,0],[\"inputId\"]],null],[10],[1,0,0,0,[27,[24,0],[\"themeInstance\",\"searchLabelMsg\"]]],[11],[1,1,0,0,\" \"],[7,\"input\",[[14,\"id\",[27,[24,0],[\"inputId\"]],null],[14,\"class\",[31,354,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"input\"]],\" filterString\"],null],null],[14,\"placeholder\",[27,[24,0],[\"themeInstance\",\"searchPlaceholderMsg\"]],null],[23,\"type\",\"text\",null]],[[\"@value\",\"@enter\"],[[27,[24,0],[\"value\"]],[27,[24,0],[\"noop\"]]]],null],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,0],[\"globalFilterUsed\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[9,\"i\",true],[13,\"class\",[32,[\"clearFilterIcon \",[27,[24,0],[\"themeInstance\",\"clearFilterIcon\"]]]],null],[12,\"role\",\"button\",null],[13,\"aria-label\",[27,[24,0],[\"themeInstance\",\"clearGlobalFilterMsg\"]],null],[13,\"onclick\",[31,736,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"clearGlobalFilter\"]]],null],null],[10],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"concat\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/global-filter.hbs"
    }
  });

  _exports.default = _default;
});