define("ember-models-table/components/models-table/pagination-simple", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/pagination-simple"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _paginationSimple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  /**
   * Simple navigation (first, prev, next, last) used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PaginationSimple />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PaginationSimple as |PS|>
   *       <button
   *         class="{{if PS.goToBackEnabled "enabled" "disabled"}} {{MT.themeInstance.buttonDefault}}"
   *         {{action PS.goToFirst}}>
   *         <i class={{MT.themeInstance.navFirstIcon}}></i>
   *       </button>
   *       <button
   *         class="{{if PS.goToBackEnabled "enabled" "disabled"}} {{MT.themeInstance.buttonDefault}}"
   *         {{action PS.goToPrev}}>
   *         <i class={{MT.themeInstance.navPrevIcon}}></i>
   *       </button>
   *       <button
   *         class="{{if PS.goToForwardEnabled "enabled" "disabled"}} {{MT.themeInstance.buttonDefault}}"
   *         {{action PS.goToNext}}>
   *         <i class={{MT.themeInstance.navNextIcon}}></i>
   *       </button>
   *       <button
   *         class="{{if PS.goToForwardEnabled "enabled" "disabled"}} {{MT.themeInstance.buttonDefault}}"
   *         {{action PS.goToLast}}>
   *         <i class={{MT.themeInstance.navLastIcon}}></i>
   *       </button>
   *       <PS.PageNumberSelect/>
   *     </Footer.PaginationSimple>
   *   </MT.Footer>
   * </ModelsTable>
   * ```
   * ModelsTablePaginationSimple yields references to the following contextual components:
   *
   * * PageNumberSelect - selectbox with list of available pages
   *
   * References to the following properties are yielded:
   *
   * * [goToBackEnabled](Components.ModelsTablePaginationSimple.html#property_goToBackEnabled) - `true` is user is not in the first page
   * * [goToForwardEnabled](Components.ModelsTablePaginationSimple.html#property_goToForwardEnabled) - `true` if user is not in the last page
   *
   * References to the following actions are yielded:
   *
   * * [goToPage](Components.ModelsTablePaginationSimple.html#event_goToPage) - action to navigate user to the custom page
   * * [goToFirst](Components.ModelsTablePaginationSimple.html#event_goToFirst) - action to navigate user to the first page
   * * [goToPrev](Components.ModelsTablePaginationSimple.html#event_goToPrev) - action to navigate user to the previous page
   * * [goToNext](Components.ModelsTablePaginationSimple.html#event_goToNext) - action to navigate user to the next page
   * * [goToLast](Components.ModelsTablePaginationSimple.html#event_goToLast) - action to navigate user to the last page
   *
   * @class ModelsTablePaginationSimple
   * @namespace Components
   * @extends Ember.Component
   */
  let PaginationSimpleComponent = (_dec = (0, _component.layout)(_paginationSimple.default), _dec2 = Ember.computed.alias('themeInstance.paginationWrapper'), _dec3 = Ember.computed.alias('themeInstance.paginationWrapperDefault'), _dec4 = Ember.computed.gt('currentPageNumber', 1), _dec5 = Ember.computed('currentPageNumber', 'pagesCount'), _dec6 = Ember.computed('elementId'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec(_class = (_class2 = class PaginationSimpleComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "themePaginationWrapperClass", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "themePaginationWrapperDefaultClass", _descriptor2, this);
      (0, _defineProperty2.default)(this, "currentPageNumber", 1);
      (0, _defineProperty2.default)(this, "recordsCount", null);
      (0, _defineProperty2.default)(this, "pagesCount", null);
      (0, _defineProperty2.default)(this, "currentPageNumberOptions", null);
      (0, _defineProperty2.default)(this, "showCurrentPageNumberSelect", null);
      (0, _defineProperty2.default)(this, "goToPage", null);
      (0, _defineProperty2.default)(this, "pageSize", 10);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _initializerDefineProperty2.default)(this, "goToBackEnabled", _descriptor3, this);
    }

    /**
     * Are buttons "Next" and "Last" enabled
     *
     * @property goToForwardEnabled
     * @type boolean
     * @default false
     * @protected
     */
    get goToForwardEnabled() {
      return this.currentPageNumber < this.pagesCount;
    }
    /**
     * @property inputId
     * @type string
     * @private
     */


    get inputId() {
      return `${this.elementId}-page-number-select`;
    }
    /**
     * @event gotoFirst
     * @param {Event} e
     * @protected
     */


    gotoFirst(e) {
      if (e) {
        e.stopPropagation();
      }

      if (!this.goToBackEnabled) {
        return false;
      }

      this.goToPage(1);
      return false;
    }
    /**
     * @event gotoPrev
     * @param {Event} e
     * @protected
     */


    gotoPrev(e) {
      if (e) {
        e.stopPropagation();
      }

      if (!this.goToBackEnabled) {
        return false;
      }

      if (this.currentPageNumber > 1) {
        this.goToPage(this.currentPageNumber - 1);
      }

      return false;
    }
    /**
     * @event gotoNext
     * @param {Event} e
     * @protected
     */


    gotoNext(e) {
      if (e) {
        e.stopPropagation();
      }

      if (!this.goToForwardEnabled) {
        return false;
      }

      const pageSize = parseInt(this.pageSize, 10);

      if (this.recordsCount > pageSize * (this.currentPageNumber - 1)) {
        this.goToPage(this.currentPageNumber + 1);
      }

      return false;
    }
    /**
     * @event gotoLast
     * @param {Event} e
     * @protected
     */


    gotoLast(e) {
      if (e) {
        e.stopPropagation();
      }

      if (!this.goToForwardEnabled) {
        return;
      }

      const pageSize = parseInt(this.pageSize, 10);
      let pageNumber = this.recordsCount / pageSize;
      pageNumber = 0 === pageNumber % 1 ? pageNumber : Math.floor(pageNumber) + 1;
      this.goToPage(pageNumber);
      return false;
    }
    /**
     * @event gotoPage
     * @param {number} pageNumber
     * @protected
     */


    gotoPage(pageNumber) {
      this.goToPage(pageNumber);
    }
    /**
     * @event noop
     * @protected
     */


    noop() {}

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themePaginationWrapperClass", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themePaginationWrapperDefaultClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "goToBackEnabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "goToForwardEnabled", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "goToForwardEnabled"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "inputId", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "gotoFirst", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoFirst"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "gotoPrev", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoPrev"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "gotoNext", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoNext"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "gotoLast", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoLast"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "gotoPage", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoPage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "noop", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype)), _class2)) || _class);
  _exports.default = PaginationSimpleComponent;
});