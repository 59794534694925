define("ember-models-table/components/models-table/themes/ember-bootstrap-v3/data-group-by-select", ["exports", "@ember-decorators/component", "ember-models-table/components/models-table/data-group-by-select", "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/data-group-by-select"], function (_exports, _component, _dataGroupBySelect, _dataGroupBySelect2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class Ebs3ModelsTableDataGroupBySelect
   * @namespace Components
   * @extends Components.ModelsTableDataGroupBySelect
   */
  let Ebs4ModelsTableDataGroupBySelectComponent = (_dec = (0, _component.layout)(_dataGroupBySelect2.default), _dec(_class = class Ebs4ModelsTableDataGroupBySelectComponent extends _dataGroupBySelect.default {}) || _class);
  _exports.default = Ebs4ModelsTableDataGroupBySelectComponent;
});