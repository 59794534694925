define("ember-models-table/templates/components/models-table/expand-all-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XPjCmoeM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"button\",true],[13,\"class\",[31,19,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonLink\"]],\" \",[27,[24,0],[\"themeInstance\",\"expandAllRows\"]]],null],null],[13,\"onclick\",[31,125,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"doExpandAllRows\"]]],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"expandAllRowsIcon\"]],null],[10],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[9,\"br\",true],[10],[11],[1,1,0,0,\"\\n\"],[9,\"button\",true],[13,\"class\",[31,247,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonLink\"]],\" \",[27,[24,0],[\"themeInstance\",\"collapseAllRows\"]]],null],null],[13,\"onclick\",[31,355,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"doCollapseAllRows\"]]],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"collapseAllRowsIcon\"]],null],[10],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[16,1,null]],\"hasEval\":false,\"upvars\":[\"concat\",\"fn\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/expand-all-toggle.hbs"
    }
  });

  _exports.default = _default;
});