define("ember-models-table/components/models-table/row-filtering", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-filtering", "ember-models-table/utils/macros"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _rowFiltering, _macros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  /**
   * Table header item used within [models-table/table-header](Components.ModelsTableTableHeader.html).
   *
   * Component generates tr with filter inputs in the separated cells. Filter for column may be as an input or select. Second one may have predefined set of options or may calculate list of options from table data. Last scenario doesn't work with [models-table-server-paginated](Components.ModelsTableServerPaginated.html). Check properties [disableFiltering](Utils.ModelsTableColumn.html#property_disableFiltering), [filteredBy](Utils.ModelsTableColumn.html#property_filteredBy), [filterFunction](Utils.ModelsTableColumn.html#property_filterFunction), [filterPlaceholder](Utils.ModelsTableColumn.html#property_filterPlaceholder), [filterWithSelect](Utils.ModelsTableColumn.html#property_filterWithSelect), [predefinedFilterOptions](Utils.ModelsTableColumn.html#property_predefinedFilterOptions) for ModelsTableColumn.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowFiltering>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowFiltering as |RF|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <RF.RowFilteringCell @column={{column}} as |RowFilteringCellContent|>
   *             <RowFilteringCellContent/>
   *           </RF.RowFilteringCell>
   *         {{/each}}
   *       </Header.RowFiltering>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * ModelsTableRowFiltering yields references to the following contextual components:
   *
   * * [models-table/row-filtering-cell](Components.ModelsTableRowFilteringCell.html) - component used as filter row cell. It shows input or select or custom component. Check filter-options for columns
   *
   * Check own docs for each component to get detailed info.
   *
   * References to the following properties are yielded:
   *
   * * [shouldAddExtraColumn](Components.ModelsTableRowFiltering.html#property_shouldAddExtraColumn) - determines if extra column should be added to the row in the `thead`. It happens when rows grouping is used and extra column with group values exists
   *
   * @class ModelsTableRowFiltering
   * @namespace Components
   * @extends Ember.Component
   */
  let RowFilteringComponent = (_dec = (0, _component.layout)(_rowFiltering.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = (0, _macros.shownColumns)('colspanForFilterCell'), _dec4 = Ember.computed('displayGroupedValueAs', 'useDataGrouping', 'visibleProcessedColumns.[]'), _dec(_class = _dec2(_class = (_class2 = class RowFilteringComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "processedColumns", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "useDataGrouping", null);
      (0, _defineProperty2.default)(this, "displayGroupedValueAs", null);
      (0, _defineProperty2.default)(this, "sort", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "toggleAllSelection", null);
      (0, _initializerDefineProperty2.default)(this, "shownColumns", _descriptor, this);
    }

    /**
     * @property shouldAddExtraColumn
     * @type boolean
     * @default false
     * @protected
     */
    get shouldAddExtraColumn() {
      return this.displayGroupedValueAs === 'column' && this.useDataGrouping && !!this.visibleProcessedColumns.length;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "shownColumns", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "shouldAddExtraColumn", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldAddExtraColumn"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = RowFilteringComponent;
});