define("ember-models-table/templates/components/models-table/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "laDI9jh4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,[[31,37,4,[27,[26,3,\"CallHead\"],[]],null,[[\"summary\"],[[27,[24,0],[\"summary\"]]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[9,\"form\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"form\"]],null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"formElementWrapper\"]],null],[10],[1,1,0,0,\"\\n      \"],[9,\"label\",true],[13,\"for\",[27,[24,0],[\"inputId\"]],null],[10],[1,1,0,0,\"\\n        \"],[1,0,0,0,[27,[24,0],[\"summary\"]]],[1,1,0,0,\"\\n        \"],[9,\"a\",true],[12,\"href\",\"#\",null],[12,\"role\",\"button\",null],[13,\"class\",[31,334,6,[27,[26,1,\"CallHead\"],[]],[\"clearFilters \",[31,358,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"anyFilterUsed\"]],\"hidden\"],null]],null],null],[13,\"onclick\",[31,417,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"doClearFilters\"]]],null],null],[10],[1,1,0,0,\"\\n          \"],[9,\"i\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"clearAllFiltersIcon\"]],null],[10],[11],[1,1,0,0,\"\\n          \"],[9,\"span\",true],[12,\"class\",\"emt-sr-only\",null],[10],[1,0,0,0,[27,[24,0],[\"themeInstance\",\"clearAllFiltersMsg\"]]],[11],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n\"],[1,1,0,0,\"      \"],[9,\"input\",true],[13,\"id\",[27,[24,0],[\"inputId\"]],null],[13,\"class\",[31,727,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"input\"]],\" invisible\"],null],null],[10],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unless\",\"concat\",\"fn\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/summary.hbs"
    }
  });

  _exports.default = _default;
});