define("ember-models-table/components/models-table/columns-hidden", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember-decorators/component", "ember-models-table/templates/components/models-table/columns-hidden"], function (_exports, _defineProperty2, _component, _columnsHidden) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  /**
   * Table row used within [models-table/table-body](Components.ModelsTableTableBody.html).
   *
   * Component used when all columns are hidden and no data items are shown. Usually it contains a simple message.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#if Body.allColumnsAreHidden}}
   *         <Body.ColumnsHidden as |CH|>
   *           Any message here
   *         </Body.ColumnsHidden>
   *       {{else}}
   *         {{! ... }}
   *       {{/if}}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @class ModelsTableColumnsHidden
   * @namespace Components
   * @extends Ember.Component
   */
  let ColumnsHiddenComponent = (_dec = (0, _component.layout)(_columnsHidden.default), _dec2 = (0, _component.tagName)('tr'), _dec(_class = _dec2(_class = class ColumnsHiddenComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "columnsCount", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
    }

  }) || _class) || _class);
  _exports.default = ColumnsHiddenComponent;
});