define("ember-models-table/components/models-table/themes/ember-bootstrap-v3/row-select-all-checkbox", ["exports", "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/row-select-all-checkbox", "@ember-decorators/component", "ember-models-table/components/models-table/row-select-all-checkbox"], function (_exports, _rowSelectAllCheckbox, _component, _rowSelectAllCheckbox2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class Ebs3ModelsTableRowSelectAllCheckbox
   * @namespace Components
   * @extends Components.ModelsTableRowSelectAllCheckbox
   */
  let Ebs3ModelsTableRowSelectAllCheckboxComponent = (_dec = (0, _component.layout)(_rowSelectAllCheckbox.default), _dec(_class = class Ebs3ModelsTableRowSelectAllCheckboxComponent extends _rowSelectAllCheckbox2.default {}) || _class);
  _exports.default = Ebs3ModelsTableRowSelectAllCheckboxComponent;
});