define("ember-models-table/helpers/not-eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.notEq = notEq;

  function notEq(_ref
  /*, hash*/
  ) {
    let [v1, v2] = _ref;
    return v1 !== v2;
  }

  var _default = Ember.Helper.helper(notEq);

  _exports.default = _default;
});