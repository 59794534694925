define("ember-models-table/components/models-table/themes/ember-paper/expand-all-toggle", ["exports", "ember-models-table/templates/components/models-table/themes/ember-paper/expand-all-toggle", "@ember-decorators/component", "ember-models-table/components/models-table/expand-all-toggle"], function (_exports, _expandAllToggle, _component, _expandAllToggle2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class EpModelsTableExpandAllToggle
   * @namespace Components
   * @extends Components.ModelsTableExpandAllToggle
   */
  let EpModelsTableExpandAllToggleComponent = (_dec = (0, _component.layout)(_expandAllToggle.default), _dec(_class = class EpModelsTableExpandAllToggleComponent extends _expandAllToggle2.default {}) || _class);
  _exports.default = EpModelsTableExpandAllToggleComponent;
});