define("ember-models-table/components/models-table/themes/ember-paper/row-sorting-cell", ["exports", "@ember-decorators/component", "ember-models-table/components/models-table/row-sorting-cell", "ember-models-table/templates/components/models-table/themes/ember-paper/row-sorting-cell"], function (_exports, _component, _rowSortingCell, _rowSortingCell2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class EpModelsTableRowSortingCell
   * @namespace Components
   * @extends Components.ModelsTableRowSortingCell
   */
  let EpModelsTableRowSortingCellComponent = (_dec = (0, _component.layout)(_rowSortingCell2.default), _dec(_class = class EpModelsTableRowSortingCellComponent extends _rowSortingCell.default {}) || _class);
  _exports.default = EpModelsTableRowSortingCellComponent;
});