define("ember-models-table/templates/components/models-table/grouped-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kygtcDIy",
    "block": "{\"symbols\":[\"cell\",\"&default\"],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[28,[24,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,2,[[31,32,4,[27,[26,3,\"CallHead\"],[]],null,[[\"groupedHeader\",\"shouldAddExtraColumn\"],[[27,[24,0],[\"groupedHeader\"]],[27,[24,0],[\"shouldAddExtraColumn\"]]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"shouldAddExtraColumn\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"th\",true],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[5,[27,[26,2,\"BlockHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"groupedHeader\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"th\",true],[13,\"colspan\",[27,[24,1],[\"colspan\"]],null],[13,\"rowspan\",[27,[24,1],[\"rowspan\"]],null],[10],[1,0,0,0,[27,[24,1],[\"title\"]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/grouped-header.hbs"
    }
  });

  _exports.default = _default;
});