define("ember-power-select/templates/components/power-select/no-matches-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K6fA1hdz",
    "block": "{\"symbols\":[\"@noMatchesMessage\"],\"statements\":[[9,\"ul\",true],[12,\"class\",\"ember-power-select-options\",null],[12,\"role\",\"listbox\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"li\",true],[12,\"class\",\"ember-power-select-option ember-power-select-option--no-matches-message\",null],[12,\"role\",\"option\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[24,1],[]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/no-matches-message.hbs"
    }
  });

  _exports.default = _default;
});