define("ember-power-select/templates/components/power-select/before-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "It0lco09",
    "block": "{\"symbols\":[\"@select\",\"@listboxId\",\"@searchPlaceholder\",\"@onInput\",\"@onFocus\",\"@onBlur\",\"@searchEnabled\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,7],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"ember-power-select-search\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"input\",false],[23,\"autocomplete\",\"off\",null],[23,\"autocorrect\",\"off\",null],[23,\"autocapitalize\",\"off\",null],[23,\"spellcheck\",\"false\",null],[23,\"role\",\"combobox\",null],[23,\"class\",\"ember-power-select-search-input\",null],[14,\"value\",[27,[24,1],[\"searchText\"]],null],[14,\"aria-controls\",[27,[24,2],[]],null],[14,\"placeholder\",[27,[24,3],[]],null],[23,\"type\",\"search\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[\"input\",[27,[24,4],[]]],null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[\"focus\",[27,[24,5],[]]],null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[\"blur\",[27,[24,6],[]]],null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[\"keydown\",[27,[24,0],[\"handleKeydown\"]]],null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[\"focusInput\"]]],null],[10],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/before-options.hbs"
    }
  });

  _exports.default = _default;
});