define("ember-models-table/themes/plain-html", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-models-table/themes/default"], function (_exports, _defineProperty2, _default) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PlainHtmlTheme
   * @namespace Themes
   * @extends Themes.Default
   */
  class PlainHtmlTheme extends _default.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "componentsPath", 'models-table/themes/plain-html/');
      (0, _defineProperty2.default)(this, "headerWrapper", 'models-table-header');
      (0, _defineProperty2.default)(this, "tfooterInternalWrapper", 'models-table-footer');
      (0, _defineProperty2.default)(this, "paginationInternalWrapper", 'pagination-wrapper');
      (0, _defineProperty2.default)(this, "currentPageSizeSelectWrapper", 'page-size-select-wrapper');
      (0, _defineProperty2.default)(this, "columnsDropdownWrapper", 'columns-dropdown');
      (0, _defineProperty2.default)(this, "clearFilterIcon", 'fa fa-times form-control-feedback');
      (0, _defineProperty2.default)(this, "clearAllFiltersIcon", 'fa fa-times');
      (0, _defineProperty2.default)(this, "sortAscIcon", 'fa fa-sort-asc');
      (0, _defineProperty2.default)(this, "sortDescIcon", 'fa fa-sort-desc');
      (0, _defineProperty2.default)(this, "columnVisibleIcon", 'fa fa-check-square-o');
      (0, _defineProperty2.default)(this, "columnHiddenIcon", 'fa fa-square-o');
      (0, _defineProperty2.default)(this, "navFirstIcon", 'fa fa-angle-double-left');
      (0, _defineProperty2.default)(this, "navPrevIcon", 'fa fa-angle-left');
      (0, _defineProperty2.default)(this, "navNextIcon", 'fa fa-angle-right');
      (0, _defineProperty2.default)(this, "navLastIcon", 'fa fa-angle-double-right');
      (0, _defineProperty2.default)(this, "caretIcon", 'caret');
      (0, _defineProperty2.default)(this, "expandRowIcon", 'fa fa-plus');
      (0, _defineProperty2.default)(this, "expandAllRowsIcon", 'fa fa-plus');
      (0, _defineProperty2.default)(this, "collapseRowIcon", 'fa fa-minus');
      (0, _defineProperty2.default)(this, "collapseAllRowsIcon", 'fa fa-minus');
      (0, _defineProperty2.default)(this, "selectAllRowsIcon", 'fa fa-check-square-o');
      (0, _defineProperty2.default)(this, "selectSomeRowsIcon", 'fa fa-minus-square-o');
      (0, _defineProperty2.default)(this, "deselectAllRowsIcon", 'fa fa-square-o');
      (0, _defineProperty2.default)(this, "selectRowIcon", 'fa fa-check-square-o');
      (0, _defineProperty2.default)(this, "deselectRowIcon", 'fa fa-square-o');
    }

  }

  _exports.default = PlainHtmlTheme;
});