define("ember-power-select/templates/components/power-select/power-select-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tXFb8URI",
    "block": "{\"symbols\":[\"@group\",\"&default\"],\"statements\":[[9,\"li\",true],[12,\"class\",\"ember-power-select-group\",null],[13,\"aria-disabled\",[31,53,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"disabled\"]],\"true\"],null],null],[12,\"role\",\"option\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[12,\"class\",\"ember-power-select-group-name\",null],[10],[1,0,0,0,[27,[24,1],[\"groupName\"]]],[11],[1,1,0,0,\"\\n  \"],[16,2,null],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/power-select-group.hbs"
    }
  });

  _exports.default = _default;
});