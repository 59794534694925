define("ember-models-table/components/models-table/cell-content-edit", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember-decorators/component", "ember-models-table/templates/components/models-table/cell-content-edit"], function (_exports, _defineProperty2, _component, _cellContentEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * Component for cells in the edit-mode.
   *
   * Properties and event-handlers from [models-table/cell](Components.ModelsTableCell.html) are bound here
   *
   * @namespace Components
   * @class ModelsTableCellContentEdit
   * @extends Ember.Component
   */
  let CellContentEditComponent = (_dec = (0, _component.layout)(_cellContentEdit.default), _dec(_class = class CellContentEditComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "record", null);
      (0, _defineProperty2.default)(this, "index", null);
      (0, _defineProperty2.default)(this, "column", null);
      (0, _defineProperty2.default)(this, "isEditRow", null);
      (0, _defineProperty2.default)(this, "groupedLength", null);
      (0, _defineProperty2.default)(this, "expandRow", null);
      (0, _defineProperty2.default)(this, "collapseRow", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "editRow", null);
      (0, _defineProperty2.default)(this, "saveRow", null);
      (0, _defineProperty2.default)(this, "cancelEditRow", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "isExpanded", null);
      (0, _defineProperty2.default)(this, "isSelected", null);
      (0, _defineProperty2.default)(this, "isColumnEditable", false);
    }

    init() {
      if (this.themeInstance) {
        Ember.set(this, 'tagName', this.themeInstance.cellContentTagName);
      }

      super.init(...arguments);
    }

  }) || _class);
  _exports.default = CellContentEditComponent;
});