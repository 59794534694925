define("ember-models-table/templates/components/models-table/themes/ember-paper/cell-content-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IUKJVKWW",
    "block": "{\"symbols\":[\"value\",\"&default\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[31,8,3,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"record\"]],[27,[24,0],[\"column\",\"propertyName\"]]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,\"paper-input\",[],[[\"@value\",\"@onChange\"],[[27,[24,0],[\"value\"]],[31,112,2,[27,[26,1,\"CallHead\"],[]],[[31,116,3,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[]]],null]],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[16,2,null]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"get\",\"let\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/cell-content-edit.hbs"
    }
  });

  _exports.default = _default;
});