define("ember-models-table/templates/components/models-table/cell-content-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mk3iQkVj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",[[14,\"class\",[27,[24,0],[\"themeInstance\",\"input\"]],null],[23,\"type\",\"text\",null]],[[\"@value\"],[[31,72,3,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"record\"]],[27,[24,0],[\"column\",\"propertyName\"]]],null]]],null],[1,1,0,0,\"\\n\"],[16,1,null]],\"hasEval\":false,\"upvars\":[\"get\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell-content-edit.hbs"
    }
  });

  _exports.default = _default;
});