define("ember-models-table/templates/components/models-table/themes/plain-html/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OzilFTz2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"globalSearch\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"themeInstance\",\"searchLabelMsg\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[9,\"label\",true],[13,\"for\",[27,[24,0],[\"inputId\"]],null],[10],[1,0,0,0,[27,[24,0],[\"themeInstance\",\"searchLabelMsg\"]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"    \"],[7,\"input\",[[14,\"id\",[27,[24,0],[\"inputId\"]],null],[14,\"class\",[31,268,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"input\"]],\" filterString\"],null],null]],[[\"@value\",\"@placeholder\",\"@oninput\"],[[27,[24,0],[\"value\"]],[27,[24,0],[\"themeInstance\",\"searchPlaceholderMsg\"]],[27,[24,0],[\"updateGlobalFilterString\"]]]],null],[1,1,0,0,\"\\n    \"],[9,\"button\",true],[12,\"class\",\"clearFilterIcon\",null],[13,\"disabled\",[31,546,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"globalFilterUsed\"]],\"disabled\"],null],null],[13,\"onClick\",[31,605,2,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"clearGlobalFilter\"]]],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"i\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"clearFilterIcon\"]],null],[10],[11],[1,1,0,0,\"\\n      \"],[9,\"span\",true],[12,\"class\",\"emt-sr-only\",null],[10],[1,0,0,0,[27,[24,0],[\"themeInstance\",\"clearGlobalFilterMsg\"]]],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"unless\",\"fn\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/plain-html/global-filter.hbs"
    }
  });

  _exports.default = _default;
});