define("ember-models-table/templates/components/models-table/themes/ember-paper/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kKHM3CtQ",
    "block": "{\"symbols\":[\"opt\",\"&default\"],\"statements\":[[7,\"paper-select\",[],[[\"@label\",\"@options\",\"@selected\",\"@disabled\",\"@placeholder\",\"@onChange\"],[[27,[24,0],[\"label\"]],[27,[24,0],[\"options\"]],[27,[24,0],[\"value\"]],[27,[24,0],[\"disabled\"]],[27,[24,0],[\"placeholder\"]],[27,[24,0],[\"updateValue\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[31,218,16,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"span\",true],[13,\"aria-valuetext\",[27,[24,1],[\"value\"]],null],[10],[1,0,0,0,[27,[24,1],[\"label\"]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[27,[24,1],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"],[16,2,null]],\"hasEval\":false,\"upvars\":[\"option-is-object\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/select.hbs"
    }
  });

  _exports.default = _default;
});