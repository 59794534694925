define("ember-models-table/templates/components/models-table/cell-edit-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E6KGjmNH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,0],[\"isEditRow\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"button\",true],[13,\"class\",[31,47,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonDefault\"]],\" \",[27,[24,0],[\"themeInstance\",\"cancelRowButton\"]]],null],null],[13,\"onclick\",[27,[24,0],[\"cancelClicked\"]],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[24,0],[\"cancelButtonLabel\"]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"button\",true],[13,\"class\",[31,253,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonDefault\"]],\" \",[27,[24,0],[\"themeInstance\",\"saveRowButton\"]]],null],null],[13,\"onclick\",[27,[24,0],[\"saveClicked\"]],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[24,0],[\"saveButtonLabel\"]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[9,\"button\",true],[13,\"class\",[31,463,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonDefault\"]],\" \",[27,[24,0],[\"themeInstance\",\"editRowButton\"]]],null],null],[13,\"onclick\",[27,[24,0],[\"editClicked\"]],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[24,0],[\"editButtonLabel\"]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[16,1,null]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell-edit-toggle.hbs"
    }
  });

  _exports.default = _default;
});