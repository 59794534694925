define("ember-models-table/components/models-table/expand-toggle", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-models-table/templates/components/models-table/expand-toggle"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _expandToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  /**
   * Component to expand or collapse any single row
   *
   * Usage example:
   *
   * ```js
   * const columns = [
   *   {
   *     component: 'models-table/expand-toggle',
   *     mayBeHidden: false
   *   },
   *   {propertyName: 'firstName'},
   *   {propertyName: 'lastName'}
   * ];
   *
   * const data = [ ... ];
   * ```
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @expandedRowComponent={{component "path/to/your-component"}}
   * />
   * ```
   *
   * @class ModelsTableExpandToggle
   * @extends Ember.Component
   * @namespace Components
   */
  let ExpandToggleComponent = (_dec = (0, _component.layout)(_expandToggle.default), _dec2 = Ember._action, _dec3 = Ember._action, _dec(_class = (_class2 = class ExpandToggleComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "record", null);
      (0, _defineProperty2.default)(this, "index", null);
      (0, _defineProperty2.default)(this, "column", null);
      (0, _defineProperty2.default)(this, "isEditRow", null);
      (0, _defineProperty2.default)(this, "groupedLength", null);
      (0, _defineProperty2.default)(this, "expandRow", null);
      (0, _defineProperty2.default)(this, "collapseRow", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "editRow", null);
      (0, _defineProperty2.default)(this, "saveRow", null);
      (0, _defineProperty2.default)(this, "cancelEditRow", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "isExpanded", null);
      (0, _defineProperty2.default)(this, "isSelected", null);
      (0, _defineProperty2.default)(this, "isColumnEditable", false);
    }

    /**
     * @event doCollapseRow
     * @param {number} index
     * @param {object} record
     * @param {Event} e
     * @protected
     */
    doCollapseRow(index, record, e) {
      this.collapseRow(index, record);
      e.stopPropagation();
      return false;
    }
    /**
     * @event doExpandRow
     * @param {number} index
     * @param {object} record
     * @param {Event} e
     * @protected
     */


    doExpandRow(index, record, e) {
      this.expandRow(index, record);
      e.stopPropagation();
      return false;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doCollapseRow", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "doCollapseRow"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doExpandRow", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "doExpandRow"), _class2.prototype)), _class2)) || _class);
  _exports.default = ExpandToggleComponent;
});