define("ember-models-table/templates/components/models-table/cell-content-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eVE/mlRi",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,2,3,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"record\"]],[27,[24,0],[\"column\",\"propertyName\"]]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell-content-display.hbs"
    }
  });

  _exports.default = _default;
});