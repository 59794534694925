define("ember-models-table/templates/components/models-table/page-size-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NFleU2FR",
    "block": "{\"symbols\":[\"PageSizeSelect\",\"&default\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[31,11,4,[27,[26,2,\"CallHead\"],[]],null,[[\"Select\"],[[31,37,9,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"selectComponent\"]]],[[\"id\",\"options\",\"value\",\"type\",\"themeInstance\",\"class\"],[[27,[24,0],[\"inputId\"]],[27,[24,0],[\"pageSizeOptions\"]],[27,[24,0],[\"pageSize\"]],[27,[24,0],[\"type\"]],[27,[24,0],[\"themeInstance\"]],\"changePageSize\"]]]]]]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[28,[24,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,2,[[27,[24,1],[]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"form\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"form\"]],null],[10],[1,1,0,0,\"\\n      \"],[9,\"div\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"formElementWrapper\"]],null],[10],[1,1,0,0,\"\\n        \"],[9,\"label\",true],[13,\"for\",[27,[24,0],[\"inputId\"]],null],[10],[1,0,0,0,[27,[24,0],[\"themeInstance\",\"rowsCountMsg\"]]],[11],[1,1,0,0,\"\\n        \"],[7,[27,[24,1],[\"Select\"]],[],[[],[]],null],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\",\"let\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/page-size-select.hbs"
    }
  });

  _exports.default = _default;
});