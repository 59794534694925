define("ember-models-table/components/models-table/cell-column-summary", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/cell-column-summary"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _cellColumnSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function sumBy(collection) {
    return Ember.computed(`${collection}.[]`, function () {
      const c = Ember.get(this, collection);
      return c ? c.reduce((a, b) => a + b, 0) : 0;
    });
  }

  function avgBy(collection, sumBy) {
    return Ember.computed(sumBy, function () {
      const count = Ember.get(this, `${collection}.length`);
      return count ? Ember.get(this, sumBy) / count : 0;
    });
  }

  function minBy(collection) {
    return Ember.computed(`${collection}.[]`, function () {
      return Math.min.apply(Math, Ember.get(this, collection));
    });
  }

  function maxBy(collection) {
    return Ember.computed(`${collection}.[]`, function () {
      return Math.max.apply(Math, Ember.get(this, collection));
    });
  }

  function medianBy(collection) {
    return Ember.computed(`${collection}.[]`, function () {
      let c = Ember.get(this, collection);

      if (!Ember.get(c, 'length')) {
        return null;
      }

      c = c.slice().sort((a, b) => a - b);
      let lowMiddle = Math.floor((c.length - 1) / 2);
      let highMiddle = Math.ceil((c.length - 1) / 2);
      return (c[lowMiddle] + c[highMiddle]) / 2;
    });
  }
  /**
   * Component for table-footer cells. Used as column-summary.
   *
   * It yields several properties:
   *
   * * [minSelected](Components.ModelsTableCellColumnSummary.html#property_minSelected) - min of selected items
   * * [maxSelected](Components.ModelsTableCellColumnSummary.html#property_maxSelected) - max of selected items
   * * [sumSelected](Components.ModelsTableCellColumnSummary.html#property_sumSelected) - sum of selected items
   * * [avgSelected](Components.ModelsTableCellColumnSummary.html#property_avgSelected) - average of selected items
   * * [medianSelected](Components.ModelsTableCellColumnSummary.html#property_medianSelected) - median of selected items
   * * [minData](Components.ModelsTableCellColumnSummary.html#property_minData) - min of data
   * * [maxData](Components.ModelsTableCellColumnSummary.html#property_maxData) - max of data
   * * [sumData](Components.ModelsTableCellColumnSummary.html#property_sumData) - sum of data
   * * [avgData](Components.ModelsTableCellColumnSummary.html#property_avgData) - average of data
   * * [medianData](Components.ModelsTableCellColumnSummary.html#property_medianData) - median of data
   *
   * Here `selectedItems` and `data` are bound from `models-table` and are mapped by `column.propertyName`.
   *
   * Component should be used only for column with set `propertyName`.
   *
   * Component should be extended or its template should be overridden.
   *
   * @namespace Components
   * @class ModelsTableCellColumnSummary
   * @extends Ember.Component
   */


  let CellColumnSummaryComponent = (_dec = (0, _component.layout)(_cellColumnSummary.default), _dec2 = (0, _component.tagName)('td'), _dec3 = minBy('mappedSelectedItems'), _dec4 = minBy('mappedData'), _dec5 = maxBy('mappedSelectedItems'), _dec6 = maxBy('mappedData'), _dec7 = sumBy('mappedSelectedItems'), _dec8 = sumBy('mappedData'), _dec9 = avgBy('mappedSelectedItems', 'sumSelected'), _dec10 = avgBy('mappedData', 'sumData'), _dec11 = medianBy('mappedSelectedItems'), _dec12 = medianBy('mappedData'), _dec(_class = _dec2(_class = (_class2 = class CellColumnSummaryComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "mappedSelectedItems", []);
      (0, _defineProperty2.default)(this, "mappedExpandedItems", []);
      (0, _defineProperty2.default)(this, "mappedData", []);
      (0, _initializerDefineProperty2.default)(this, "minSelected", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "minData", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "maxSelected", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "maxData", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "sumSelected", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "sumData", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "avgSelected", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "avgData", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "medianSelected", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "medianData", _descriptor10, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "minSelected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "minData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "maxSelected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "maxData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sumSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sumData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "avgSelected", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "avgData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "medianSelected", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "medianData", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = CellColumnSummaryComponent;
});