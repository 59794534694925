define("ember-models-table/components/models-table/global-filter", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/global-filter"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _globalFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor;

  /**
   * Global filter element used within [models-table](Components.ModelsTable.html).
   *
   * Its value is used for all data-items and for each columns `propertyName`.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.GlobalFilter />
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.GlobalFilter>
   *     {{input value=MT.globalFilter}}
   *     <button disabled={{if MT.globalFilterUsed "disabled"}} {{action (mut MT.globalFilter) ""}}>
   *       Clear Global Filter
   *     </button>
   *   </MT.GlobalFilter>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @namespace Components
   * @class ModelsTableGlobalFilter
   * @extends Ember.Component
   */
  let GlobalFilterComponent = (_dec = (0, _component.layout)(_globalFilter.default), _dec2 = Ember.computed.alias('themeInstance.globalFilterWrapper'), _dec3 = Ember.computed('elementId'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = (_class2 = class GlobalFilterComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "globalFilterWrapper", _descriptor, this);
      (0, _defineProperty2.default)(this, "value", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "globalFilterUsed", null);
    }

    /**
     * @property inputId
     * @type string
     * @private
     */
    get inputId() {
      return `${this.elementId}-global-filter`;
    }
    /**
     * @event noop
     * @protected
     */


    noop() {}
    /**
     * @event updateGlobalFilterString
     * @protected
     * @param {Event} e
     */


    updateGlobalFilterString(e) {
      if (e) {
        e.stopPropagation();
      }

      Ember.set(this, 'value', e.target.value);
      return false;
    }
    /**
     * @event clearGlobalFilter
     * @protected
     * @param {Event} e
     */


    clearGlobalFilter(e) {
      if (e) {
        e.stopPropagation();
      }

      Ember.set(this, 'value', '');
      return false;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "globalFilterWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "inputId", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "noop", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateGlobalFilterString", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "updateGlobalFilterString"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clearGlobalFilter", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "clearGlobalFilter"), _class2.prototype)), _class2)) || _class);
  _exports.default = GlobalFilterComponent;
});