define("ember-models-table/components/models-table/data-group-by-select", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/data-group-by-select"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _dataGroupBySelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  /**
   * Dropdown to select property for table-rows grouping
   *
   * Also component allows to select sort order for property used for grouping
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @useDataGrouping={{true}}
   *   @currentGroupingPropertyName="firstName"
   *   @displayGroupedValueAs="column"
   *   @dataGroupProperties={{dataGroupProperties}} as |MT|>
   *   <MT.DataGroupBySelect />
   *   {{! ... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @useDataGrouping={{true}}
   *   @currentGroupingPropertyName="firstName"
   *   @displayGroupedValueAs="column"
   *   @dataGroupProperties={{dataGroupProperties}} as |MT|>
   *   <MT.DataGroupBySelect as |DGBS|>
   *     <label>{{MT.themeInstance.groupByLabelMsg}}</label>
   *     <DGBS.Select />
   *     <button
   *       class={{MT.themeInstance.sortGroupedPropertyBtn}}
   *       onclick={{action DGBS.sort}}>
   *       <i class={{if
   *        (is-equal MT.sortByGroupedFieldDirection "asc")
   *        MT.themeInstance.sortAscIcon
   *        MT.themeInstance.sortDescIcon}}>
   *       </i>
   *     </button>
   *   </MT.DataGroupBySelect>
   * </ModelsTable>
   * ```
   *
   * References to the following actions are yielded:
   *
   * * [sort](Components.ModelsTableDataGroupBySelect.html#event_doSort) - do sort by property name used to group rows
   *
   * @class ModelsTableDataGroupBySelect
   * @namespace Components
   * @extends Ember.Component
   */
  let DataGroupBySelectComponent = (_dec = (0, _component.layout)(_dataGroupBySelect.default), _dec2 = Ember.computed.alias('themeInstance.dataGroupBySelectWrapper'), _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = (_class2 = class DataGroupBySelectComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "dataGroupBySelectWrapper", _descriptor, this);
      (0, _defineProperty2.default)(this, "value", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "options", null);
      (0, _defineProperty2.default)(this, "currentGroupingPropertyName", null);
      (0, _defineProperty2.default)(this, "sortByGroupedFieldDirection", null);
      (0, _defineProperty2.default)(this, "sort", null);
    }

    /**
     * @event doSort
     * @protected
     */
    doSort() {
      this.sort({
        propertyName: this.currentGroupingPropertyName
      });
    }
    /**
     * @event noop
     * @protected
     */


    noop() {}

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dataGroupBySelectWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doSort", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "doSort"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "noop", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype)), _class2)) || _class);
  _exports.default = DataGroupBySelectComponent;
});