define("ember-models-table/templates/components/models-table/themes/ember-paper/row-select-all-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zz61G6w1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"paper-checkbox\",[],[[\"@indeterminate\",\"@value\",\"@onChange\"],[[31,35,3,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"selectedItems\",\"length\"]],[31,66,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"selectedItems\",\"length\"]],[27,[24,0],[\"data\",\"length\"]]],null]],null],[31,131,8,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"selectedItems\",\"length\"]],[27,[24,0],[\"data\",\"length\"]]],null],[27,[24,0],[\"doToggleAllSelection\"]]]],null],[1,1,0,0,\"\\n\"],[16,1,null]],\"hasEval\":false,\"upvars\":[\"not-eq\",\"and\",\"is-equal\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/row-select-all-checkbox.hbs"
    }
  });

  _exports.default = _default;
});