define("ember-models-table/components/models-table/row-grouping", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-grouping"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _rowGrouping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  /**
   * Component-row with grouped property value. Used to toggle rows group with needed property value equal to the grouping value
   *
   * You must not override this component and should use [groupingRowComponent](Components.ModelsTable.html#property_groupingRowComponent)
   *
   * By default [row-group-toggle](Components.ModelsTableRowGroupToggle.html) is used
   *
   * @namespace Components
   * @class ModelsTableRowGrouping
   * @extends Ember.Component
   */
  let RowGroupingComponent = (_dec = (0, _component.layout)(_rowGrouping.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = Ember.computed.alias('themeInstance.groupingRow'), _dec4 = Ember.computed('displayGroupedValueAs', 'visibleProcessedColumns.length', 'additionalColspan'), _dec5 = Ember.computed.intersect('selectedItems', 'groupedItems'), _dec6 = Ember.computed.intersect('expandedItems', 'groupedItems'), _dec(_class = _dec2(_class = (_class2 = class RowGroupingComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "themeGroupingRowClass", _descriptor, this);
      (0, _defineProperty2.default)(this, "groupedValue", null);
      (0, _defineProperty2.default)(this, "groupIsCollapsed", null);
      (0, _defineProperty2.default)(this, "groupedLength", null);
      (0, _defineProperty2.default)(this, "additionalColspan", 0);
      (0, _defineProperty2.default)(this, "groupedItems", null);
      (0, _defineProperty2.default)(this, "visibleGroupedItems", null);
      (0, _initializerDefineProperty2.default)(this, "selectedGroupedItems", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "expandedGroupedItems", _descriptor3, this);
      (0, _defineProperty2.default)(this, "currentGroupingPropertyName", null);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRows", null);
      (0, _defineProperty2.default)(this, "displayGroupedValueAs", null);
      (0, _defineProperty2.default)(this, "groupingRowComponent", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRowsSelection", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRowsExpands", null);
    }

    /**
     * @property cellColspan
     * @type number
     * @default null
     * @protected
     */
    get cellColspan() {
      return this.visibleProcessedColumns.length + (this.displayGroupedValueAs === 'row' ? 0 : 1) + Number(this.additionalColspan);
    }
    /**
     * Extra colspan used in the internal `td`. Useful in cases with block scope usage when some extra columns are in the table (not only `columns`)
     *
     * @property additionalColspan
     * @type number
     * @default 0
     */


  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themeGroupingRowClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "cellColspan", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "cellColspan"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectedGroupedItems", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "expandedGroupedItems", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = RowGroupingComponent;
});