define("ember-models-table/components/models-table/row-sorting-cell", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-sorting-cell"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _rowSortingCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  /**
   * Sort-row cell used within [models-table/row-sorting](Components.ModelsTableRowSorting.html).
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowSorting as |RS|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <RS.RowSortingCell @column={{column}} />
   *         {{/each}}
   *       </Header.RowSorting>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   * If `column.componentForSortCell` is provided it is yielded with next properties and actions:
   *
   * * [column](Component.ModelsTableCellContentDisplay.html#property_column)
   * * [selectedItems](Component.ModelsTableCellContentDisplay.html#property_selectedItems)
   * * [expandedItems](Component.ModelsTableCellContentDisplay.html#property_expandedItems)
   * * [data](Component.ModelsTableCellContentDisplay.html#property_data)
   * * [themeInstance](Component.ModelsTableCellContentDisplay.html#property_themeInstance)
   * * [expandAllRows](Component.ModelsTableCellContentDisplay.html#event_expandAllRows)
   * * [collapseAllRows](Component.ModelsTableCellContentDisplay.html#event_collapseAllRows)
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowSorting as |RS|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <RS.RowSortingCell @column={{column}} as |RowSortingCellContent|>
   *             <RowSortingCellContent/>
   *           </RS.RowSortingCell>
   *         {{/each}}
   *       </Header.RowSorting>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @namespace Components
   * @class ModelsTableRowSortingCell
   * @extends Ember.Component
   */
  let RowSortingCellComponent = (_dec = (0, _component.layout)(_rowSortingCell.default), _dec2 = (0, _component.tagName)('th'), _dec3 = Ember.computed.alias('themeInstance.theadCell'), _dec4 = Ember.computed.alias('column.className'), _dec5 = Ember.computed.readOnly('column.realColspanForSortCell'), _dec(_class = _dec2(_class = (_class2 = class RowSortingCellComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "themeTheadCellClass", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "columnClassName", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "colspan", _descriptor3, this);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "sort", null);
      (0, _defineProperty2.default)(this, "column", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "toggleAllSelection", null);
    }

    click() {
      if (Ember.get(this.column, 'useSorting')) {
        this.sort(this.column);
      }

      return false;
    }
    /**
     * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
     *
     * @property themeInstance
     * @type object
     * @default null
     */


  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themeTheadCellClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "columnClassName", [_component.className, _dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "colspan", [_component.attribute, _dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = RowSortingCellComponent;
});