define("ember-models-table/components/models-table/columns-dropdown", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-models-table/templates/components/models-table/columns-dropdown"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _columnsDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2;

  /**
   * Dropdown with list of table's columns used within [models-table](Components.ModelsTable.html).
   *
   * It allows to toggle visibility for column sets or single column.
   *
   * Columns that should not be hidden must have property [mayBeHidden](Utils.ModelsTableColumn.html#property_mayBeHidden) set to `false`.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.ColumnsDropdown />
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.ColumnsDropdown as |CD|>
   *     {{#if MT.columnDropdownOptions.showAll}}
   *       <button {{action MT.showAllColumns}}>Show all</button>
   *     {{/if}}
   *     {{#if MT.columnDropdownOptions.hideAll}}
   *       <button {{action MT.hideAllColumns}}>Hide all</button>
   *     {{/if}}
   *     {{#if MT.columnDropdownOptions.restoreDefaults}}
   *       <button {{action MT.restoreDefaultVisibility}}>Restore default visibility</button>
   *     {{/if}}
   *     {{#each MT.columnDropdownOptions.columnSets as |columnSet|}}
   *       <button {{action MT.toggleColumnSetVisibility columnSet}}>{{columnSet.label}}</button>
   *     {{/each}}
   *     {{#each MT.processedColumns as |column|}}
   *       {{#if column.mayBeHidden}}
   *         <button {{action MT.toggleHidden column}}>
   *           <i class={{if column.toggleColumnVisibility MT.themeInstance.columnVisibleIcon MT.themeInstance.columnHiddenIcon}}></i>
   *           {{column.title}}
   *         </button>
   *       {{/if}}
   *     {{/each}}
   *   </MT.ColumnsDropdown>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @namespace Components
   * @class ModelsTableColumnsDropdown
   * @extends Ember.Component
   */
  let ColumnsDropdownComponent = (_dec = (0, _component.layout)(_columnsDropdown.default), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = (_class2 = class ColumnsDropdownComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "processedColumns", null);
      (0, _defineProperty2.default)(this, "columnDropdownOptions", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "showAllColumns", null);
      (0, _defineProperty2.default)(this, "hideAllColumns", null);
      (0, _defineProperty2.default)(this, "restoreDefaultVisibility", null);
      (0, _defineProperty2.default)(this, "toggleColumnSet", null);
      (0, _defineProperty2.default)(this, "toggleHidden", null);
    }

    /**
     * @event doShowAllColumns
     * @param {Event} e
     * @protected
     */
    doShowAllColumns(e) {
      this.showAllColumns();

      if (e) {
        e.stopPropagation();
      }

      return false;
    }
    /**
     * @event doHideAllColumns
     * @param {Event} e
     * @protected
     */


    doHideAllColumns(e) {
      this.hideAllColumns();

      if (e) {
        e.stopPropagation();
      }

      return false;
    }
    /**
     * @event doRestoreDefaultVisibility
     * @param {Event} e
     * @protected
     */


    doRestoreDefaultVisibility(e) {
      this.restoreDefaultVisibility();

      if (e) {
        e.stopPropagation();
      }

      return false;
    }
    /**
     * @event doToggleColumnSet
     * @param {ColumnSet} columnSet
     * @param {Event} e
     * @protected
     */


    doToggleColumnSet(columnSet, e) {
      this.toggleColumnSet(columnSet);

      if (e) {
        e.stopPropagation();
      }

      return false;
    }
    /**
     * @event doToggleHidden
     * @param {Utils.ModelsTableColumn} column
     * @param {Event} e
     * @protected
     */


    doToggleHidden(column, e) {
      this.toggleHidden(column);

      if (e) {
        e.stopPropagation();
      }

      return false;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doShowAllColumns", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "doShowAllColumns"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doHideAllColumns", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "doHideAllColumns"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doRestoreDefaultVisibility", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "doRestoreDefaultVisibility"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doToggleColumnSet", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleColumnSet"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doToggleHidden", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleHidden"), _class2.prototype)), _class2)) || _class);
  _exports.default = ColumnsDropdownComponent;
});