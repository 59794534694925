define("ember-models-table/templates/components/models-table/columns-hidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EboI0l79",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"td\",true],[13,\"colspan\",[27,[24,0],[\"columnsCount\"]],null],[13,\"class\",[27,[24,0],[\"themeInstance\",\"noDataCell\"]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,1,[[31,119,4,[27,[26,1,\"CallHead\"],[]],null,[[\"columnsCount\",\"themeInstance\"],[[27,[24,0],[\"columnsCount\"]],[27,[24,0],[\"themeInstance\"]]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,242,9,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"allColumnsAreHiddenMsg\"]]],null]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"html-safe\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/columns-hidden.hbs"
    }
  });

  _exports.default = _default;
});