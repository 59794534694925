define("ember-models-table/components/models-table/row", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/initializerWarningHelper", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row"], function (_exports, _initializerDefineProperty2, _defineProperty2, _initializerWarningHelper2, _applyDecoratedDescriptor2, _component, _row) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  /**
   * Table body row is used within [models-table/table-body](Components.ModelsTableTableBody.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#each Body.visibleContent as |record index|}}
   *         <Body.Row @record={{record}} @index={{index}} />
   *       {{/each}}
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#each Body.visibleContent as |record index|}}
   *         <Body.Row @record={{record}} @index={{index}} as |Row|>
   *           {{#each MT.visibleProcessedColumns as |column|}}
   *             <Row.Cell @column={{column}} @index={{index}} />
   *           {{/each}}
   *         </Body.Row>
   *       {{/each}}
   *       {{! ... }}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * **Important!** You must bound `record` and `index` to the `row`.
   *
   * ModelsTableTableRow yields references to the following contextual components:
   *
   * * [models-table/cell](Components.ModelsTableCell.html) - component represents each row's cell
   * * [models-table/row-group-toggle](Components.ModelsTableRowGroupToggle.html) - component is used to toggle rows group visibility
   * * [models-table/row-select-checkbox](Components.ModelsTableRowSelectCheckbox.html) - component is used to toggle row selection
   * * [models-table/expand-toggle](Components.ModelsTableExpandToggle.html) - component is used to expand or collapse row
   *
   * Check own docs for each component to get detailed info.
   *
   * References to the following properties are yielded:
   *
   * * [isEditRow](Components.ModelsTableRow.html#property_isEditRow) - `true` if row in the Edit-mode
   * * [isFirstGroupedRow](Components.ModelsTableRow.html#property_isFirstGroupedRow) - `true` if row is first in the rows group (flag used when rows grouping is used)
   * * [rowspanForFirstCell](Component.ModelsTablRow.html#property_rowspanForFirstCell)
   *
   * References to the following actions are yielded:
   *
   * * [editRow](Components.ModelsTableRow.html#event_editRow) - action to set row to the Edit-mode
   * * [saveRow](Components.ModelsTableRow.html#event_saveRow) - action to save row and turn off Edit-mode
   * * [cancelEditRow](Components.ModelsTableRow.html#event_cancelEditRow) - action to cancel changes done to the row and turn off Edit-mode
   *
   * @class ModelsTableRow
   * @namespace Components
   * @extends Ember.Component
   */
  let RowComponent = (_dec = (0, _component.layout)(_row.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = Ember.computed('isSelected', 'themeInstance.selectedRow'), _dec4 = Ember.computed('isExpanded', 'themeInstance.expandedRow'), _dec5 = Ember.computed('visibleGroupedItems.length', 'expandedGroupItems.length', 'groupSummaryRowComponent'), _dec6 = Ember.computed.intersect('selectedItems', 'groupedItems'), _dec7 = Ember.computed.intersect('expandedItems', 'groupedItems'), _dec8 = Ember.computed.intersect('expandedItems', 'visibleGroupedItems'), _dec9 = Ember.computed.equal('index', 0), _dec10 = Ember.computed('selectedItems.[]', 'record'), _dec11 = Ember.computed('expandedItems.[]', 'record'), _dec12 = Ember.computed('displayGroupedValueAs', 'isFirstGroupedRow', 'useDataGrouping'), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class RowComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "index", null);
      (0, _defineProperty2.default)(this, "record", null);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "currentGroupingPropertyName", null);
      (0, _defineProperty2.default)(this, "collapsedGroupValues", null);
      (0, _defineProperty2.default)(this, "groupedItems", null);
      (0, _defineProperty2.default)(this, "visibleGroupedItems", null);
      (0, _defineProperty2.default)(this, "useDataGrouping", null);
      (0, _defineProperty2.default)(this, "displayGroupedValueAs", null);
      (0, _initializerDefineProperty2.default)(this, "selectedGroupedItems", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "expandedGroupedItems", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "expandedGroupItems", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isFirstGroupedRow", _descriptor4, this);
      (0, _defineProperty2.default)(this, "groupedValue", null);
      (0, _defineProperty2.default)(this, "groupedLength", null);
      (0, _defineProperty2.default)(this, "clickOnRow", null);
      (0, _defineProperty2.default)(this, "doubleClickOnRow", null);
      (0, _defineProperty2.default)(this, "hoverOnRow", null);
      (0, _defineProperty2.default)(this, "outRow", null);
      (0, _defineProperty2.default)(this, "expandRow", null);
      (0, _defineProperty2.default)(this, "collapseRow", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRows", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRowsSelection", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRowsExpands", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "isEditRow", false);
    }

    /**
     * @property tagName
     * @type string
     * @default 'tr'
     */

    /**
     * @property rowSelectedClass
     * @protected
     * @type string
     * @default ''
     */
    get rowSelectedClass() {
      return this.isSelected ? this.themeInstance.selectedRow : '';
    }
    /**
     * @property rowExpandedClass
     * @protected
     * @type string
     * @default ''
     */


    get rowExpandedClass() {
      return this.isExpanded ? this.themeInstance.expandedRow : '';
    }
    /**
     * @property rowspanForFirstCell
     * @type number
     * @protected
     */


    get rowspanForFirstCell() {
      const rowspan = this.visibleGroupedItems.length + this.expandedGroupItems.length;
      return this.groupSummaryRowComponent ? rowspan + 1 : rowspan;
    }
    /**
     * Row's index
     *
     * @property index
     * @type number
     * @default null
     */


    /**
     * @protected
     * @property isSelected
     * @type boolean
     * @default false
     */
    get isSelected() {
      return Ember.isArray(this.selectedItems) && this.selectedItems.includes(this.record);
    }
    /**
     * @protected
     * @property isExpanded
     * @type boolean
     * @default false
     */


    get isExpanded() {
      return Ember.isArray(this.expandedItems) && this.expandedItems.includes(this.record);
    }
    /**
     * @protected
     * @property shouldShowGroupToggleCell
     * @type boolean
     * @default false
     */


    get shouldShowGroupToggleCell() {
      return this.displayGroupedValueAs === 'column' && this.isFirstGroupedRow && this.useDataGrouping;
    }
    /**
     * @property groupedValue
     * @type *
     * @default null
     */


    click() {
      this.clickOnRow(this.index, this.record);
      return false;
    }

    doubleClick() {
      this.doubleClickOnRow(this.index, this.record);
    }

    enter() {
      this.hoverOnRow(this.index, this.record);
    }

    leave() {
      this.outRow(this.index, this.record);
    }

    didInsertElement() {
      this.element.addEventListener('mouseenter', this.handleMouseEnter);
      this.element.addEventListener('mouseleave', this.handleMouseLeave);
      super.didInsertElement(...arguments);
    }

    willDestroyElement() {
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
      super.willDestroyElement(...arguments);
    }
    /**
     * @protected
     * @event handleMouseEnter
     */


    handleMouseEnter() {
      this.enter();
    }
    /**
     * @protected
     * @event handleMouseLeave
     */


    handleMouseLeave() {
      this.leave();
    }
    /**
     * @protected
     * @event doToggleGroupedRows
     */


    doToggleGroupedRows() {
      this.toggleGroupedRows(this.groupedValue);
      return false;
    }
    /**
     * Place a row into edit mode
     *
     * @protected
     * @event editRow
     */


    editRow() {
      Ember.set(this, 'isEditRow', true);
    }
    /**
     * Indicate a row has been saved, the row is no longer in edit mode
     *
     * @protected
     * @event saveRow
     */


    saveRow() {
      Ember.set(this, 'isEditRow', false);
    }
    /**
     * Indicate the edit on the row has been cancelled, the row is no longer in edit mode
     *
     * @protected
     * @event cancelEditRow
     */


    cancelEditRow() {
      Ember.set(this, 'isEditRow', false);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "rowSelectedClass", [_component.className, _dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "rowSelectedClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "rowExpandedClass", [_component.className, _dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "rowExpandedClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "rowspanForFirstCell", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "rowspanForFirstCell"), _class2.prototype), _descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectedGroupedItems", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "expandedGroupedItems", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "expandedGroupItems", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isFirstGroupedRow", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isSelected", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "isSelected"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isExpanded", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "isExpanded"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "shouldShowGroupToggleCell", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldShowGroupToggleCell"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleMouseEnter", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseEnter"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleMouseLeave", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseLeave"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doToggleGroupedRows", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleGroupedRows"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editRow", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "editRow"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "saveRow", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "saveRow"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "cancelEditRow", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "cancelEditRow"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = RowComponent;
});