define("ember-models-table/templates/components/models-table/themes/ember-paper/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nJST8hxi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,[[31,37,4,[27,[26,2,\"CallHead\"],[]],null,[[\"summary\"],[[27,[24,0],[\"summary\"]]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[27,[24,0],[\"summary\"]]],[1,1,0,0,\"\\n  \"],[7,\"paper-button\",[[23,\"class\",\"clearFilters\",null]],[[\"@disabled\",\"@onClick\"],[[31,172,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"anyFilterUsed\"]],\"disabled\"],null],[31,227,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"doClearFilters\"]]],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[7,\"paper-icon\",[],[[\"@icon\"],[[27,[24,0],[\"themeInstance\",\"clearAllFiltersIcon\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n      \"],[9,\"span\",true],[12,\"class\",\"emt-sr-only\",null],[10],[1,0,0,0,[27,[24,0],[\"themeInstance\",\"clearAllFiltersMsg\"]]],[11],[1,1,0,0,\"\\n    \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unless\",\"fn\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/summary.hbs"
    }
  });

  _exports.default = _default;
});