define("ember-models-table/components/models-table/footer", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/footer"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _footer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  /**
   * Footer block used within [models-table](Components.ModelsTable.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer />
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage example with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.Summary />
   *     <Footer.PageSizeSelect />
   *     {{#if useNumericPagination}}
   *       <Footer.PaginationNumeric />
   *     {{else}}
   *       <Footer.PaginationSimple />
   *     {{/if}}
   *   </MT.Footer>
   * </ModelsTable>
   * ```
   *
   * ModelsTableFooter yields references to the following contextual components:
   *
   * * [models-table/summary](Components.ModelsTableSummary.html) - component with summary info about table data. It also contains button to clear all filters applied to the table
   * * [models-table/size-select](Components.ModelsTableSizeSelect.html) - component with a page sizes dropdown. It allows to select number if records shown on page
   * * [models-table/pagination-numeric](Components.ModelsTablePaginationNumeric.html) - component with a table navigation. It allows to move to the page by its number
   * * [models-table/pagination-simple](Components.ModelsTablePaginationSimple.html) - component with a table navigation. It allows to move to the first, last, prev and next pages (this four buttons are shown always)
   *
   * Check own docs for each component to get detailed info.
   *
   * @class ModelsTableFooter
   * @namespace Components
   * @extends Ember.Component
   */
  let FooterComponent = (_dec = (0, _component.layout)(_footer.default), _dec2 = Ember.computed.alias('themeInstance.tfooterInternalWrapper'), _dec(_class = (_class2 = class FooterComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "tfooterInternalWrapper", _descriptor, this);
      (0, _defineProperty2.default)(this, "collapseNumPaginationForPagesCount", null);
      (0, _defineProperty2.default)(this, "firstIndex", null);
      (0, _defineProperty2.default)(this, "lastIndex", null);
      (0, _defineProperty2.default)(this, "recordsCount", null);
      (0, _defineProperty2.default)(this, "anyFilterUsed", null);
      (0, _defineProperty2.default)(this, "currentPageNumberOptions", null);
      (0, _defineProperty2.default)(this, "pageSizeOptions", null);
      (0, _defineProperty2.default)(this, "pageSize", 10);
      (0, _defineProperty2.default)(this, "currentPageNumber", 1);
      (0, _defineProperty2.default)(this, "showCurrentPageNumberSelect", null);
      (0, _defineProperty2.default)(this, "pagesCount", null);
      (0, _defineProperty2.default)(this, "showPageSize", null);
      (0, _defineProperty2.default)(this, "useNumericPagination", null);
      (0, _defineProperty2.default)(this, "goToPage", null);
      (0, _defineProperty2.default)(this, "clearFilters", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tfooterInternalWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = FooterComponent;
});