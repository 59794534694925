define("ember-models-table/components/models-table/table", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/table"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;

  /**
   * Table with data used within [models-table](Components.ModelsTable.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table />
   *   {{! ... }}
   * </ModelsTable>
   * ```
   *
   * Usage example with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header />
   *     <Table.Body />
   *     <Table.Footer />
   *   </MT.Table>
   * </ModelsTable>
   * ```
   *
   * ModelsTableTable yields references to the following contextual components:
   *
   * * [models-table/table-header](Components.ModelsTableTableHeader.html) - table header. Component contains several rows like grouped headers, row with sorting buttons (usually with column titles) and row with filters for each column
   * * [models-table/table-body](Components.ModelsTableTableBody.html) - table body with data. Component contains current page with a subset or rows
   * * [models-table/table-footer](Components.ModelsTableTableFooter.html) - table footer. It's empty by default
   *
   * Check own docs for each component to get detailed info.
   *
   * @namespace Components
   * @class ModelsTableTable
   * @extends Ember.Component
   */
  let TableComponent = (_dec = (0, _component.layout)(_table.default), _dec2 = (0, _component.tagName)('table'), _dec3 = Ember.computed.alias('themeInstance.table'), _dec4 = Ember.computed.alias('processedColumns.length'), _dec5 = Ember.computed('visibleProcessedColumns.@each.componentForFooterCell'), _dec6 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class TableComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "themeTableClass", _descriptor, this);
      (0, _defineProperty2.default)(this, "noHeaderFilteringAndSorting", null);
      (0, _defineProperty2.default)(this, "groupedHeaders", null);
      (0, _defineProperty2.default)(this, "processedColumns", null);
      (0, _defineProperty2.default)(this, "sort", null);
      (0, _initializerDefineProperty2.default)(this, "columnsCount", _descriptor2, this);
      (0, _defineProperty2.default)(this, "visibleContent", null);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "allColumnsAreHidden", null);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "useFilteringByColumns", null);
      (0, _defineProperty2.default)(this, "groupingRowComponent", null);
      (0, _defineProperty2.default)(this, "groupSummaryRowComponent", null);
      (0, _defineProperty2.default)(this, "displayGroupedValueAs", null);
      (0, _defineProperty2.default)(this, "currentGroupingPropertyName", null);
      (0, _defineProperty2.default)(this, "collapsedGroupValues", null);
      (0, _defineProperty2.default)(this, "dataGroupOptions", null);
      (0, _defineProperty2.default)(this, "groupedVisibleContentValuesOrder", null);
      (0, _defineProperty2.default)(this, "groupedVisibleContent", null);
      (0, _defineProperty2.default)(this, "groupedArrangedContent", null);
      (0, _defineProperty2.default)(this, "useDataGrouping", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRows", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRowsSelection", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRowsExpands", null);
      (0, _defineProperty2.default)(this, "clickOnRow", null);
      (0, _defineProperty2.default)(this, "doubleClickOnRow", null);
      (0, _defineProperty2.default)(this, "hoverOnRow", null);
      (0, _defineProperty2.default)(this, "outRow", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "groupHeaderCellComponent", null);
      (0, _defineProperty2.default)(this, "expandRow", null);
      (0, _defineProperty2.default)(this, "collapseRow", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "toggleAllSelection", null);
    }

    /**
     * @property showTableFooter
     * @type boolean
     * @default false
     * @protected
     */
    get showTableFooter() {
      return Ember.A(this.visibleProcessedColumns).isAny('componentForFooterCell');
    }
    /**
     * @event doSort
     * @param {Utils.ModelsTableColumn} column
     * @protected
     */


    doSort(column) {
      this.sort(column);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themeTableClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "columnsCount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showTableFooter", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "showTableFooter"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doSort", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "doSort"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = TableComponent;
});