define("ember-models-table/utils/column", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.propertyNameToTitle = propertyNameToTitle;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  /*
   * Convert some string to the human readable one
   *
   * @param {string} name value to convert
   * @return string
   */
  function propertyNameToTitle(name) {
    return Ember.String.capitalize(Ember.String.dasherize(name).replace(/-/g, ' '));
  }
  /**
   * Column definition class for ModelsTable columns
   *
   * Check its public properties
   *
   * @class ModelsTableColumn
   * @extends Ember.Object
   * @namespace Utils
   */


  let ModelsTableColumn = (_dec = Ember.computed.readOnly('__mt.data'), _dec2 = Ember.computed('propertyName'), _dec3 = Ember.computed.not('isHidden'), _dec4 = Ember.computed.equal('sorting', 'asc'), _dec5 = Ember.computed.equal('sorting', 'desc'), _dec6 = Ember.computed.notEmpty('filterString'), _dec7 = Ember.computed('sortField', 'disableSorting'), _dec8 = Ember.computed('sortedBy', 'propertyName'), _dec9 = Ember.computed('filterField', 'disableFiltering'), _dec10 = Ember.computed('filteredBy', 'propertyName'), _dec11 = (0, _object.observes)('filterWithSelect', 'filterOptions.[]', 'filterString'), (_class = class ModelsTableColumn extends Ember.Object {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "propertyName", '');
      (0, _defineProperty2.default)(this, "title", null);
      (0, _defineProperty2.default)(this, "simple", false);
      (0, _defineProperty2.default)(this, "component", '');
      (0, _defineProperty2.default)(this, "componentForEdit", '');
      (0, _defineProperty2.default)(this, "editable", true);
      (0, _defineProperty2.default)(this, "componentForFilterCell", '');
      (0, _defineProperty2.default)(this, "componentForSortCell", '');
      (0, _defineProperty2.default)(this, "componentForFooterCell", '');
      (0, _defineProperty2.default)(this, "colspanForSortCell", 1);
      (0, _defineProperty2.default)(this, "realColspanForSortCell", 1);
      (0, _defineProperty2.default)(this, "colspanForFilterCell", 1);
      (0, _defineProperty2.default)(this, "realColspanForFilterCell", 1);
      (0, _defineProperty2.default)(this, "sortedBy", null);
      (0, _defineProperty2.default)(this, "sortDirection", '');
      (0, _defineProperty2.default)(this, "sortPrecedence", null);
      (0, _defineProperty2.default)(this, "disableSorting", false);
      (0, _defineProperty2.default)(this, "disableFiltering", false);
      (0, _defineProperty2.default)(this, "filterString", '');
      (0, _defineProperty2.default)(this, "filteredBy", null);
      (0, _defineProperty2.default)(this, "sorting", '');
      (0, _defineProperty2.default)(this, "isHidden", false);
      (0, _defineProperty2.default)(this, "mayBeHidden", true);
      (0, _defineProperty2.default)(this, "filterWithSelect", false);
      (0, _defineProperty2.default)(this, "sortFilterOptions", false);
      (0, _defineProperty2.default)(this, "predefinedFilterOptions", null);
      (0, _defineProperty2.default)(this, "className", '');
      (0, _defineProperty2.default)(this, "filterFunction", null);
      (0, _defineProperty2.default)(this, "sortFunction", null);
      (0, _defineProperty2.default)(this, "filterPlaceholder", '');
      (0, _defineProperty2.default)(this, "routeName", '');
      (0, _defineProperty2.default)(this, "routeProperty", 'id');
      (0, _defineProperty2.default)(this, "originalDefinition", null);
      (0, _defineProperty2.default)(this, "__mt", null);
      (0, _initializerDefineProperty2.default)(this, "data", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "isVisible", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "sortAsc", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "sortDesc", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "filterUsed", _descriptor5, this);
    }

    /**
     * @property cssPropertyName
     * @type string
     * @protected
     */
    get cssPropertyName() {
      return this.propertyName.replace(/\./g, '-');
    }
    /**
     * @property isVisible
     * @protected
     * @type boolean
     * @default true
     */


    /**
     * Allow sorting for column or not
     *
     * @property useSorting
     * @protected
     * @type boolean
     * @default true
     */
    get useSorting() {
      return this.sortField && !this.disableSorting;
    }
    /**
     * @property sortField
     * @protected
     * @type string
     */


    get sortField() {
      return this.sortedBy || this.propertyName;
    }
    /**
     * Allow filtering for column or not
     *
     * @protected
     * @property useFilter
     * @type boolean
     * @default true
     */


    get useFilter() {
      return this.filterField && !this.disableFiltering;
    }
    /**
     * @protected
     * @property filterField
     * @type string
     */


    get filterField() {
      return this.filteredBy || this.propertyName;
    }
    /**
     * If preselected option doesn't exist after `filterOptions` update,
     * `filterString` is reverted to empty string (basically, filtering for this column is dropped)
     *
     * @method cleanFilterString
     * @protected
     */


    cleanFilterString() {
      const {
        filterOptions,
        filterWithSelect,
        filterString
      } = this;

      if (!filterWithSelect || Ember.isEmpty(filterOptions)) {
        return;
      }

      const filterOptionExists = Ember.A(filterOptions).find(option => {
        const value = Ember.get(option, 'value');
        return [value, '' + value].indexOf(filterString) !== -1;
      });

      if (!filterOptionExists) {
        Ember.set(this, 'filterString', '');
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cssPropertyName", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "cssPropertyName"), _class.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isVisible", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sortAsc", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sortDesc", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filterUsed", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "useSorting", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "useSorting"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sortField", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "sortField"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "useFilter", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "useFilter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filterField", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "filterField"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cleanFilterString", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "cleanFilterString"), _class.prototype)), _class));
  _exports.default = ModelsTableColumn;
});