define("ember-models-table/helpers/exists-in", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/object"], function (_exports, _applyDecoratedDescriptor2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  let ExistsInEmberObject = (_dec = Ember.computed('needle', 'haystack.[]'), _dec2 = (0, _object.observes)('content'), (_class = class ExistsInEmberObject extends Ember.Helper {
    get content() {
      const {
        needle,
        haystack
      } = this;
      return Ember.isArray(haystack) ? haystack.includes(needle) : false;
    }

    compute(_ref) {
      let [haystack, needle] = _ref;
      Ember.set(this, 'needle', needle);
      Ember.set(this, 'haystack', haystack);
      return this.content;
    }

    contentDidChange() {
      this.recompute();
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "content", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "content"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "contentDidChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "contentDidChange"), _class.prototype)), _class));
  _exports.default = ExistsInEmberObject;
});