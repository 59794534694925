define("ember-models-table/components/models-table/themes/ember-bootstrap-v3/row-filtering-cell", ["exports", "@ember-decorators/component", "ember-models-table/components/models-table/row-filtering-cell", "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/row-filtering-cell"], function (_exports, _component, _rowFilteringCell, _rowFilteringCell2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class Ebs3ModelsTableRowFilteringCell
   * @namespace Components
   * @extends Components.ModelsTableRowFilteringCell
   */
  let Ebs3ModelsTableRowFilteringCellComponent = (_dec = (0, _component.layout)(_rowFilteringCell2.default), _dec(_class = class Ebs3ModelsTableRowFilteringCellComponent extends _rowFilteringCell.default {}) || _class);
  _exports.default = Ebs3ModelsTableRowFilteringCellComponent;
});