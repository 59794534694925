define("ember-models-table/templates/components/models-table/themes/ember-paper/row-select-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZdjWXXYJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"paper-checkbox\",[],[[\"@value\",\"@bubbles\",\"@onChange\"],[[27,[24,0],[\"isSelected\"]],false,[31,82,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"doClickOnRow\"]],[27,[24,0],[\"index\"]],[27,[24,0],[\"record\"]]],null]]],null],[1,1,0,0,\"\\n\"],[16,1,null]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/row-select-checkbox.hbs"
    }
  });

  _exports.default = _default;
});