define("ember-models-table/templates/components/models-table/expand-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0YpPlIY7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,0],[\"isExpanded\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"button\",true],[13,\"class\",[31,48,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonLink\"]],\" \",[27,[24,0],[\"themeInstance\",\"collapseRow\"]]],null],null],[13,\"onclick\",[31,156,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"doCollapseRow\"]],[27,[24,0],[\"index\"]],[27,[24,0],[\"record\"]]],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"i\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"collapseRowIcon\"]],null],[10],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[9,\"button\",true],[13,\"class\",[31,309,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonLink\"]],\" \",[27,[24,0],[\"themeInstance\",\"expandRow\"]]],null],null],[13,\"onclick\",[31,415,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"doExpandRow\"]],[27,[24,0],[\"index\"]],[27,[24,0],[\"record\"]]],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"i\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"expandRowIcon\"]],null],[10],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[16,1,null]],\"hasEval\":false,\"upvars\":[\"concat\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/expand-toggle.hbs"
    }
  });

  _exports.default = _default;
});