define("ember-quill/components/quill-editor", ["exports", "quill", "ember-quill/templates/components/quill-editor"], function (exports, _quill, _quillEditor) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _quillEditor.default,
    editor: null,

    textChange() {},

    selectionChange() {},

    options: Ember.computed(function () {
      return { theme: "snow" };
    }),

    safeValue: Ember.computed("value", function () {
      return Ember.String.htmlSafe(this.get("value"));
    }),

    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup("service:fastboot");
    }),

    didInsertElement() {
      // Don't instantiate Quill if fastboot is detected
      if (this.get("fastboot.isFastBoot")) {
        return;
      }

      const editor = new _quill.default(this.element, this.get("options"));

      editor.on("text-change", (delta, oldDelta, source) => {
        this.get("textChange")(this.get("editor"), delta, oldDelta, source);
      });

      editor.on("selection-change", (delta, oldDelta, source) => {
        this.get("selectionChange")(this.get("editor"), delta, oldDelta, source);
      });

      this.set("editor", editor);
    }
  });
});