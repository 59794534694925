define("ember-cli-clipboard/components/copy-button", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-cli-clipboard/templates/components/copy-button"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _copyButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  const CLIPBOARD_EVENTS = ['success', 'error'];
  let CopyButtonComponent = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class CopyButtonComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _copyButton.default);
      (0, _defineProperty2.default)(this, "tagName", "");
      (0, _defineProperty2.default)(this, "delegateClickEvent", true);
    }

    /**
     * Assigns button element an id
     * @returns {Void}
     */
    setupElement(element) {
      element.id = Ember.guidFor(this);
      this._buttonElement = element;
    }
    /**
     * Registers ClipboardJS object with component
     * @private
     * @returns {Void}
     */


    registerClipboard() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }

      const clipboard = this._createClipboard();

      this._registerActions(clipboard);

      this.clipboard = clipboard;
    }
    /**
     * Destroys `ClipboardJS` instance
     * @returns {Void}
     */


    destroyClipboard() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
    }
    /**
     * Creates new `ClipboardJS` instance
     * @private
     * @returns {Object} newly created ClipboardJS object
     */


    _createClipboard() {
      const {
        clipboardText: text,
        delegateClickEvent
      } = this;
      const trigger = delegateClickEvent === false ? this._buttonElement : `#${this._buttonElement.id}`;
      return new window.ClipboardJS(trigger, {
        text: typeof text === 'function' ? text : undefined
      });
    }
    /**
     * Registers Ember Actions with ClipboardJS events
     * @private
     * @param {Object} clipboard - ClipboardJS object
     * @returns {Void}
     */


    _registerActions(clipboard) {
      CLIPBOARD_EVENTS.forEach(event => {
        clipboard.on(event, () => {
          if (!this._buttonElement.disabled) {
            const action = this[event];

            if (typeof action === 'string') {
              // eslint-disable-next-line ember/closure-actions
              this.sendAction(action, ...arguments);
            } else {
              action && action(...arguments);
            }
          }
        });
      });
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupElement", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "registerClipboard", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "registerClipboard"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "destroyClipboard", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "destroyClipboard"), _class.prototype)), _class));
  _exports.default = CopyButtonComponent;
});