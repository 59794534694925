define("ember-models-table/components/models-table/table-header", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/table-header"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;

  /**
   * Table header used within [models-table/table](Components.ModelsTableTable.html).
   *
   * Component contains grouped headers (if provided) bound from [ModelsTable.groupedHeaders](Components.ModelsTable.html#property_groupedHeaders), row with sorting buttons (usually this row also contains column headers) and row with filter boxes.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header />
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       {{#each groupedHeaders as |groupedHeader|}}
   *         <Header.GroupedHeader @groupedHeader={{groupedHeader}} as |GroupedHeader|>
   *           {{#each GroupedHeader.groupedHeader as |cell|}}
   *             <th colspan={{cell.colspan}} rowspan={{cell.rowspan}}>{{cell.title}}</th>
   *           {{/each}}
   *         </Header.GroupedHeader>
   *       {{/each}}
   *       <Header.RowSorting />
   *       <Header.RowFiltering />
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * ModelsTableTableHeader yields references to the following contextual components:
   *
   * * [models-table/grouped-header](Components.ModelsTableGroupedHeader.html) - component for groupedHeaders. It should be used for each groupedHeaders item
   * * [models-table/row-sorting](Components.ModelsTableRowSorting.html) - row with columns titles. Click on every cell will sort table data by selected column
   * * [models-table/row-filtering](Components.ModelsTableRowFiltering.html) - row with filter items. Every cell contains input or select-box
   * * [models-table/select-all-rows-checkbox](Components.ModelsTableSelectAllRowsCheckbox.html) - checkbox to select or deselect all rows
   * * [models-table/expand-all-toggle](Components.ModelsTableExpandAllToggle.html) - buttons to expand and collapse all rows
   *
   * Check own docs for each component to get detailed info.
   *
   * @namespace Components
   * @class ModelsTableTableHeader
   * @extends Ember.Component
   */
  let TableHeaderComponent = (_dec = (0, _component.layout)(_tableHeader.default), _dec2 = (0, _component.tagName)('thead'), _dec3 = Ember.computed.alias('themeInstance.thead'), _dec4 = (0, _component.className)('table-header-no-filtering-and-sorting'), _dec5 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class TableHeaderComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "themeTheadClass", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "noHeaderFilteringAndSorting", _descriptor2, this);
      (0, _defineProperty2.default)(this, "groupedHeaders", null);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "processedColumns", null);
      (0, _defineProperty2.default)(this, "useFilteringByColumns", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "useDataGrouping", null);
      (0, _defineProperty2.default)(this, "displayGroupedValueAs", null);
      (0, _defineProperty2.default)(this, "currentGroupingPropertyName", null);
      (0, _defineProperty2.default)(this, "groupHeaderCellComponent", null);
      (0, _defineProperty2.default)(this, "sort", null);
      (0, _defineProperty2.default)(this, "expandRow", null);
      (0, _defineProperty2.default)(this, "collapseRow", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "toggleAllSelection", null);
      (0, _defineProperty2.default)(this, "data", null);
    }

    /**
     * @event doSort
     * @param {Utils.ModelsTableColumn} column
     * @protected
     */
    doSort(column) {
      this.sort(column);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themeTheadClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "noHeaderFilteringAndSorting", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doSort", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "doSort"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = TableHeaderComponent;
});