define("ember-models-table/components/models-table/row-sorting", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-sorting", "ember-models-table/utils/macros", "ember-models-table/utils/column"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _rowSorting, _macros, _column) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor;

  /**
   * Table header item used within [models-table/table-header](Components.ModelsTableTableHeader.html).
   *
   * Component generates tr with column titles in the separated cells. Click by each cell will sort table data by selected field. Check properties [disableSorting](Utils.ModelsTableColumn.html#property_disableSorting), [sortedBy](Utils.ModelsTableColumn.html#property_sortedBy) for ModelsTableColumn.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowSorting />
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowSorting as |RS|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <td>{{column.title}}</td>
   *         {{/each}}
   *       </Header.RowSorting>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * ModelsTableRowSorting yields references to the following contextual components:
   *
   * * [models-table/row-sorting-cell](Components.ModelsTableRowSortingCell.html) - component used as sorting row cell. Clicking on it causes column sorting
   *
   * References to the following properties are yielded:
   *
   * * [shouldAddExtraColumn](Components.ModelsTableRowSorting.html#property_shouldAddExtraColumn) - determines if extra column should be added to the row in the `thead`. It happens when rows grouping is used and extra column with group values exists
   *
   * Check own docs for each component to get detailed info.
   *
   * @namespace Components
   * @class ModelsTableRowSorting
   * @extends Ember.Component
   */
  let RowSortingComponent = (_dec = (0, _component.layout)(_rowSorting.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = (0, _macros.shownColumns)('colspanForSortCell'), _dec4 = Ember.computed('displayGroupedValueAs', 'useDataGrouping', 'visibleProcessedColumns.[]'), _dec5 = Ember.computed('currentGroupingPropertyName'), _dec6 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class RowSortingComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "processedColumns", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "useDataGrouping", null);
      (0, _defineProperty2.default)(this, "displayGroupedValueAs", null);
      (0, _defineProperty2.default)(this, "groupHeaderCellComponent", null);
      (0, _defineProperty2.default)(this, "currentGroupingPropertyName", null);
      (0, _defineProperty2.default)(this, "sort", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "toggleAllSelection", null);
      (0, _initializerDefineProperty2.default)(this, "shownColumns", _descriptor, this);
    }

    /**
     * @property shouldAddExtraColumn
     * @type boolean
     * @default false
     * @protected
     */
    get shouldAddExtraColumn() {
      return this.displayGroupedValueAs === 'column' && this.useDataGrouping && !!this.visibleProcessedColumns.length;
    }
    /**
     * @property currentGroupingPropertyNameTitlelized
     * @type string
     * @protected
     */


    get currentGroupingPropertyNameTitlelized() {
      return (0, _column.propertyNameToTitle)(this.currentGroupingPropertyName);
    }
    /**
     * @event doSort
     * @param {Utils.ModelsTableColumn} column
     */


    doSort(column) {
      this.sort(column);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "shownColumns", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "shouldAddExtraColumn", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldAddExtraColumn"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "currentGroupingPropertyNameTitlelized", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "currentGroupingPropertyNameTitlelized"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doSort", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "doSort"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = RowSortingComponent;
});