define("ember-models-table/components/models-table/pagination-numeric", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/pagination-numeric"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _paginationNumeric) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;

  /**
   * Numeric navigation used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PaginationNumeric />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PaginationNumeric as |PN|>
   *       {{#each PN.visiblePageNumbers as |page|}}
   *         {{#if page.isLink}}
   *           <button
   *             class="{{MT.themeInstance.paginationNumericItem}} {{if page.isActive MT.themeInstance.paginationNumericItemActive}} {{MT.themeInstance.buttonDefault}}"
   *             {{action MT.goToPage page.label}}>
   *             {{page.label}}
   *           </button>
   *         {{else}}
   *           <button
   *             type="button"
   *             class="{{MT.themeInstance.buttonDefault}} {{MT.themeInstance.paginationNumericItem}}"
   *             disabled="disabled">
   *             {{page.label}}
   *           </button>
   *         {{/if}}
   *       {{/each}}
   *       <PN.PageNumberSelect />
   *     </Footer.PaginationNumeric>
   *   </MT.Footer>
   * </ModelsTable>
   * ```
   * ModelsTablePaginationNumeric yields references to the following contextual components:
   *
   * * PageNumberSelect - selectbox with list of available pages
   *
   * References to the following properties are yielded:
   *
   * * [visiblePageNumbers](Components.ModelsTablePaginationNumeric.html#property_visiblePageNumbers)
   *
   * @class ModelsTablePaginationNumeric
   * @namespace Components
   * @extends Ember.Component
   */
  let PaginationNumericComponent = (_dec = (0, _component.layout)(_paginationNumeric.default), _dec2 = Ember.computed.alias('themeInstance.paginationWrapper'), _dec3 = Ember.computed.alias('themeInstance.paginationWrapperNumeric'), _dec4 = Ember.computed('pagesCount', 'currentPageNumber', 'collapseNumPaginationForPagesCount'), _dec5 = Ember.computed('elementId'), _dec6 = Ember._action, _dec(_class = (_class2 = class PaginationNumericComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "themePaginationWrapperClass", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "themePaginationWrapperNumericClass", _descriptor2, this);
      (0, _defineProperty2.default)(this, "collapseNumPaginationForPagesCount", null);
      (0, _defineProperty2.default)(this, "currentPageNumber", 1);
      (0, _defineProperty2.default)(this, "showCurrentPageNumberSelect", null);
      (0, _defineProperty2.default)(this, "currentPageNumberOptions", null);
      (0, _defineProperty2.default)(this, "recordsCount", null);
      (0, _defineProperty2.default)(this, "pageSize", 10);
      (0, _defineProperty2.default)(this, "pagesCount", null);
      (0, _defineProperty2.default)(this, "goToPage", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
    }

    /**
     * List of links to the page
     * Used if [ModelsTable.useNumericPagination](Components.ModelsTable.html#property_useNumericPagination) is true
     *
     * @type object[]
     * @property visiblePageNumbers
     * @default []
     * @protected
     */
    get visiblePageNumbers() {
      const {
        pagesCount,
        currentPageNumber,
        collapseNumPaginationForPagesCount
      } = this;
      const notLinkLabel = '...';
      const showAll = pagesCount <= collapseNumPaginationForPagesCount;
      let groups = []; // array of 8 numbers

      let labels = Ember.A([]);
      groups[0] = 1;
      groups[1] = Math.min(1, pagesCount);
      groups[6] = Math.max(1, pagesCount);
      groups[7] = pagesCount;
      groups[3] = Math.max(groups[1] + 1, currentPageNumber - 1);
      groups[4] = Math.min(groups[6] - 1, currentPageNumber + 1);
      groups[2] = Math.floor((groups[1] + groups[3]) / 2);
      groups[5] = Math.floor((groups[4] + groups[6]) / 2);

      if (showAll) {
        for (let i = groups[0]; i <= groups[7]; i++) {
          labels[i] = i;
        }
      } else {
        for (let n = groups[0]; n <= groups[1]; n++) {
          labels[n] = n;
        }

        const userGroup2 = groups[4] >= groups[3] && groups[3] - groups[1] > 1;

        if (userGroup2) {
          labels[groups[2]] = notLinkLabel;
        }

        for (let i = groups[3]; i <= groups[4]; i++) {
          labels[i] = i;
        }

        const userGroup5 = groups[4] >= groups[3] && groups[6] - groups[4] > 1;

        if (userGroup5) {
          labels[groups[5]] = notLinkLabel;
        }

        for (let i = groups[6]; i <= groups[7]; i++) {
          labels[i] = i;
        }
      }

      return Ember.A(labels.compact().map(label => ({
        label,
        isLink: label !== notLinkLabel,
        isActive: label === currentPageNumber
      })));
    }
    /**
     * @property inputId
     * @type string
     * @private
     */


    get inputId() {
      return `${this.elementId}-page-number-select`;
    }
    /**
     * @event gotoCustomPage
     * @param {number} pageNumber
     * @param {Event} e
     * @protected
     */


    gotoCustomPage(pageNumber, e) {
      if (e) {
        e.stopPropagation();
      }

      this.goToPage(pageNumber);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themePaginationWrapperClass", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themePaginationWrapperNumericClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "visiblePageNumbers", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "visiblePageNumbers"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "inputId", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "gotoCustomPage", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoCustomPage"), _class2.prototype)), _class2)) || _class);
  _exports.default = PaginationNumericComponent;
});