define("ember-power-select-with-create/templates/components/power-select-with-create/suggested-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yE5neAHl",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[27,[26,0,\"Expression\"],[\"text\"]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"option\"]}",
    "meta": {
      "moduleName": "ember-power-select-with-create/templates/components/power-select-with-create/suggested-option.hbs"
    }
  });

  _exports.default = _default;
});