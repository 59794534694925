define("ember-models-table/components/models-table/themes/ember-paper/select", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/themes/ember-paper/select"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  /**
   * @class EpModelsTableSelect
   * @namespace Components
   * @extends Ember.Component
   */
  let SelectComponent = (_dec = (0, _component.layout)(_select.default), _dec2 = Ember.computed.empty('options'), _dec3 = Ember._action, _dec(_class = (_class2 = class SelectComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "disabled", _descriptor, this);
      (0, _defineProperty2.default)(this, "label", '');
      (0, _defineProperty2.default)(this, "clearable", false);
      (0, _defineProperty2.default)(this, "wide", false);
    }

    /**
     * @event updateValue
     * @param {*} val
     * @protected
     */
    updateValue(val) {
      let v = 'value' in val ? val.value : val;

      if (this.type === 'number') {
        v = +v;
      }

      Ember.set(this, 'value', v);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "disabled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateValue", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "updateValue"), _class2.prototype)), _class2)) || _class);
  _exports.default = SelectComponent;
});