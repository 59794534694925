define("ember-models-table/templates/components/models-table/row-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ENHPjlcv",
    "block": "{\"symbols\":[\"RowExpand\",\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[31,11,9,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"expandedRowComponent\"]]],[[\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"themeInstance\"],[[27,[24,0],[\"record\"]],[27,[24,0],[\"visibleProcessedColumns\"]],[27,[24,0],[\"index\"]],[27,[24,0],[\"clickOnRow\"]],[27,[24,0],[\"themeInstance\"]]]]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"td\",true],[13,\"colspan\",[27,[24,0],[\"cellColspan\"]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],[[28,[24,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"      \"],[16,2,[[27,[24,1],[]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"      \"],[7,[27,[24,1],[]],[],[[],[]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/row-expand.hbs"
    }
  });

  _exports.default = _default;
});