define("ember-models-table/components/models-table/themes/ember-paper/columns-dropdown", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/components/models-table/columns-dropdown", "ember-models-table/templates/components/models-table/themes/ember-paper/columns-dropdown"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _columnsDropdown, _columnsDropdown2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  /**
   * @class EpModelsTableColumnsDropdown
   * @namespace Components
   * @extends Components.ModelsTableColumnsDropdown
   */
  let EpModelsTableColumnsDropdownComponent = (_dec = (0, _component.layout)(_columnsDropdown2.default), _dec2 = Ember.computed.alias('themeInstance.columnsDropdownWrapper'), _dec(_class = (_class2 = class EpModelsTableColumnsDropdownComponent extends _columnsDropdown.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "columnsDropdownWrapper", _descriptor, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "columnsDropdownWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = EpModelsTableColumnsDropdownComponent;
});