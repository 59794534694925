define("ember-models-table/components/models-table/row-filtering-cell", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-filtering-cell"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _rowFilteringCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  /**
   * Filter-row cell used within [models-table/row-filtering](Components.ModelsTableRowFiltering.html).
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowFiltering as |RF|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <RF.RowFilteringCell @column={{column}} />
   *         {{/each}}
   *       </Header.RowFiltering>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * If `column.componentForFilterCell` is provided it is yielded with next properties and actions:
   *
   * * [column](Component.ModelsTableCellContentDisplay.html#property_column)
   * * [selectedItems](Component.ModelsTableCellContentDisplay.html#property_selectedItems)
   * * [expandedItems](Component.ModelsTableCellContentDisplay.html#property_expandedItems)
   * * [data](Component.ModelsTableCellContentDisplay.html#property_data)
   * * [themeInstance](Component.ModelsTableCellContentDisplay.html#property_themeInstance)
   * * [expandAllRows](Component.ModelsTableCellContentDisplay.html#event_expandAllRows)
   * * [collapseAllRows](Component.ModelsTableCellContentDisplay.html#event_collapseAllRows)
   * * [toggleAllSelection](Component.ModelsTableCellContentDisplay.html#event_toggleAllSelection)
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowFiltering as |RF|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <RF.RowFilteringCell @column={{column}} as |RowFilteringCellContent|>
   *             <RowFilteringCellContent/>
   *           </RF.RowFilteringCell>
   *         {{/each}}
   *       </Header.RowFiltering>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @namespace Components
   * @class ModelsTableRowFilteringCell
   * @extends Ember.Component
   */
  let RowFilteringCellComponent = (_dec = (0, _component.layout)(_rowFilteringCell.default), _dec2 = (0, _component.tagName)('th'), _dec3 = Ember.computed.alias('themeInstance.theadCell'), _dec4 = Ember.computed.alias('column.className'), _dec5 = Ember.computed.readOnly('column.realColspanForFilterCell'), _dec6 = Ember.computed('column.useFilter', 'themeInstance.theadCellNoFiltering'), _dec7 = Ember.computed('elementId'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class RowFilteringCellComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "themeTheadCellClass", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "columnClassName", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "colspan", _descriptor3, this);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "column", null);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "toggleAllSelection", null);
    }

    /**
     * @property filteringClassName
     * @type string
     * @protected
     */
    get filteringClassName() {
      return this.column.useFilter ? '' : this.themeInstance.theadCellNoFiltering;
    }
    /**
     * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
     *
     * @property themeInstance
     * @type object
     * @default null
     */


    /**
     * @property inputId
     * @type string
     * @protected
     */
    get inputId() {
      return `${this.elementId}-column-filter`;
    }
    /**
     * @event noop
     * @protected
     */


    noop(e) {
      e.stopPropagation();
    }
    /**
     * @event updateColumnFilterString
     * @param {Event} e
     * @protected
     */


    updateColumnFilterString(e) {
      if (e) {
        e.stopPropagation();
      }

      Ember.set(this, 'column.filterString', e.target.value);
      return false;
    }
    /**
     * @event clearFilter
     * @param {Event} e
     * @protected
     */


    clearFilter(e) {
      if (e) {
        e.stopPropagation();
      }

      Ember.set(this, 'column.filterString', '');
      return false;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themeTheadCellClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "columnClassName", [_component.className, _dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "colspan", [_component.attribute, _dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "filteringClassName", [_component.className, _dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "filteringClassName"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "inputId", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "noop", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateColumnFilterString", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "updateColumnFilterString"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clearFilter", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "clearFilter"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = RowFilteringCellComponent;
});