define("ember-models-table/templates/components/models-table/cell-column-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HSbEhUuP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,18,4,[27,[26,0,\"CallHead\"],[]],null,[[\"minSelected\",\"maxSelected\",\"sumSelected\",\"avgSelected\",\"medianSelected\",\"minData\",\"maxData\",\"sumData\",\"avgData\",\"medianData\"],[[27,[24,0],[\"minSelected\"]],[27,[24,0],[\"maxSelected\"]],[27,[24,0],[\"sumSelected\"]],[27,[24,0],[\"avgSelected\"]],[27,[24,0],[\"medianSelected\"]],[27,[24,0],[\"minData\"]],[27,[24,0],[\"maxData\"]],[27,[24,0],[\"sumData\"]],[27,[24,0],[\"avgData\"]],[27,[24,0],[\"medianData\"]]]]]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell-column-summary.hbs"
    }
  });

  _exports.default = _default;
});