define("ember-models-table/templates/components/models-table/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kur0bD8f",
    "block": "{\"symbols\":[\"opt\",\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"option\",true],[13,\"value\",[27,[24,1],[\"value\"]],null],[13,\"selected\",[31,84,8,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"value\"]],[27,[24,1],[\"value\"]],[27,[24,0],[\"type\"]]],null],null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[24,1],[\"label\"]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[16,2,null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/select.hbs"
    }
  });

  _exports.default = _default;
});