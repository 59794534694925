define("ember-power-select/templates/components/power-select/placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KtcEuGye",
    "block": "{\"symbols\":[\"&attrs\",\"@placeholder\"],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,2],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"span\",false],[23,\"class\",\"ember-power-select-placeholder\",null],[15,1],[10],[1,0,0,0,[27,[24,2],[]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/placeholder.hbs"
    }
  });

  _exports.default = _default;
});