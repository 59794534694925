define("ember-models-table/components/models-table/themes/ember-paper/pagination-simple", ["exports", "@ember-decorators/component", "ember-models-table/components/models-table/pagination-simple", "ember-models-table/templates/components/models-table/themes/ember-paper/pagination-simple"], function (_exports, _component, _paginationSimple, _paginationSimple2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class EpModelsTablePaginationSimple
   * @namespace Components
   * @extends Components.ModelsTablePaginationSimple
   */
  let EpModelsTablePaginationSimpleComponent = (_dec = (0, _component.layout)(_paginationSimple2.default), _dec(_class = class EpModelsTablePaginationSimpleComponent extends _paginationSimple.default {}) || _class);
  _exports.default = EpModelsTablePaginationSimpleComponent;
});