define("ember-models-table/templates/components/models-table/themes/plain-html/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FqBGiQD5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,[[31,37,4,[27,[26,3,\"CallHead\"],[]],null,[[\"summary\"],[[27,[24,0],[\"summary\"]]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[27,[24,0],[\"summary\"]]],[1,1,0,0,\"\\n  \"],[9,\"button\",true],[13,\"class\",[31,156,6,[27,[26,1,\"CallHead\"],[]],[\"clearFilters \",[31,180,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"anyFilterUsed\"]],\"emt-sr-only\"],null]],null],null],[13,\"onclick\",[31,238,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"doClearFilters\"]]],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"i\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"clearAllFiltersIcon\"]],null],[10],[11],[1,1,0,0,\"\\n    \"],[9,\"span\",true],[12,\"class\",\"emt-sr-only\",null],[10],[1,0,0,0,[27,[24,0],[\"themeInstance\",\"clearAllFiltersMsg\"]]],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unless\",\"concat\",\"fn\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/plain-html/summary.hbs"
    }
  });

  _exports.default = _default;
});