define("ember-models-table/templates/components/models-table/themes/ember-paper/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aV3eaEoL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"globalSearch\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[7,\"paper-input\",[[14,\"class\",[31,109,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"input\"]],\" filterString\"],null],null]],[[\"@label\",\"@placeholder\",\"@value\",\"@onChange\"],[[27,[24,0],[\"themeInstance\",\"searchLabelMsg\"]],[27,[24,0],[\"themeInstance\",\"searchPlaceholderMsg\"]],[27,[24,0],[\"value\"]],[31,323,2,[27,[26,1,\"CallHead\"],[]],[[31,327,3,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"value\"]]],null]],null]]],null],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[27,[24,0],[\"globalFilterUsed\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[7,\"paper-button\",[[14,\"class\",[31,419,6,[27,[26,0,\"CallHead\"],[]],[\"clearFilterIcon \",[27,[24,0],[\"themeInstance\",\"clearFilterIcon\"]]],null],null]],[[\"@onClick\",\"@iconButton\"],[[31,502,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"clearGlobalFilter\"]]],null],true]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n        \"],[1,0,0,0,[31,572,10,[27,[26,2,\"CallHead\"],[]],[\"close\"],null]],[1,1,0,0,\"\\n      \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"concat\",\"fn\",\"paper-icon\",\"mut\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/global-filter.hbs"
    }
  });

  _exports.default = _default;
});