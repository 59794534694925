define("ember-models-table/components/models-table/grouped-header", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-models-table/templates/components/models-table/grouped-header"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _groupedHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  /**
   * Table header item used within [models-table/table-header](Components.ModelsTableTableHeader.html).
   *
   * Each `grouped-header` should represent one item from [ModelsTable.groupedHeaders](Components.ModelsTable.html#property_groupedHeaders).
   *
   * Usage example:
   *
   * ```js
   * const groupedHeaders = [
   *   [{title: 'BigTitle', colspan: 5}],
   *   [{title: 'SubTitle1', colspan: 2}, {title: 'SubTitle2', colspan: 3}]
   * ];
   * ```
   *
   * ```hbs
   * <ModelsTable
   *   @columns={{columns}}
   *   @data={{data}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       {{#each groupedHeaders as |groupedHeader|}}
   *         <Header.GroupedHeader @groupedHeader={{groupedHeader}} as |GroupedHeader|>
   *           {{#each GroupedHeader.groupedHeader as |cell|}}
   *             <th colspan={{cell.colspan}} rowspan={{cell.rowspan}}>{{cell.title}}</th>
   *           {{/each}}
   *         </Header.GroupedHeader>
   *       {{/each}}
   *     </Table.Header>
   *   </MT.Table>
   * </ModelsTable>
   * ```
   *
   * @class ModelsTableGroupedHeader
   * @namespace Components
   * @extends Ember.Component
   */
  let GroupedHeaderComponent = (_dec = (0, _component.layout)(_groupedHeader.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = Ember.computed('displayGroupedValueAs', 'useDataGrouping', 'visibleProcessedColumns.[]'), _dec(_class = _dec2(_class = (_class2 = class GroupedHeaderComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "groupedHeader", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "useDataGrouping", null);
      (0, _defineProperty2.default)(this, "displayGroupedValueAs", null);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
    }

    /**
     * @property shouldAddExtraColumn
     * @type boolean
     * @default false
     * @protected
     */
    get shouldAddExtraColumn() {
      return this.displayGroupedValueAs === 'column' && this.useDataGrouping && !!this.visibleProcessedColumns.length;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "shouldAddExtraColumn", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldAddExtraColumn"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = GroupedHeaderComponent;
});