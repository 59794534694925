define("ember-models-table/components/models-table/cell", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/cell"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _cell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;

  /**
   * Table cell used within [models-table/table-row](Components.ModelsTableTableRow.html).
   *
   * Cell types:
   *
   * * Text. Shows some property value
   * * Route link with record id
   * * Route link with record property value
   * * Custom component
   *
   * See properties [routeName](Utils.ModelsTableColumn.html#property_routeName), [propertyName](Utils.ModelsTableColumn.html#property_propertyName), [component](Utils.ModelsTableColumn.html#property_component) for ModelsTableColumn.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#each MT.visibleContent as |record index|}}
   *         <Body.Row @record={{record}} @index={{index}} as |Row|>
   *           {{#each MT.visibleProcessedColumns as |column|}}
   *             <Row.Cell @column={{column}} @index={{index}} as |Cell|/>
   *               {{#if Cell.componentToRender}}
   *                 {{component Cell.componentToRender record=Cell.record column=column index=index}}
   *               {{/if}}
   *               {{! ... }}
   *             </Row.Cell>
   *           {{/each}}
   *         </Body.Row>
   *       {{/each}}
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @namespace Components
   * @class ModelsTableCell
   * @extends Ember.Component
   */
  let CellComponent = (_dec = (0, _component.layout)(_cell.default), _dec2 = (0, _component.tagName)('td'), _dec3 = Ember.computed.alias('column.className'), _dec4 = Ember.computed('column.editable', 'isEditRow'), _dec5 = Ember.computed('column.{component,componentForEdit,propertyName}', 'isColumnEditable', 'isEditRow', 'themeInstance.{cellContentDisplayComponent,cellContentEditComponent}'), _dec(_class = _dec2(_class = (_class2 = class CellComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "columnClassName", _descriptor, this);
      (0, _defineProperty2.default)(this, "record", null);
      (0, _defineProperty2.default)(this, "index", null);
      (0, _defineProperty2.default)(this, "column", null);
      (0, _defineProperty2.default)(this, "isEditRow", null);
      (0, _defineProperty2.default)(this, "groupedLength", null);
      (0, _defineProperty2.default)(this, "expandRow", null);
      (0, _defineProperty2.default)(this, "collapseRow", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "editRow", null);
      (0, _defineProperty2.default)(this, "saveRow", null);
      (0, _defineProperty2.default)(this, "cancelEditRow", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "isExpanded", null);
      (0, _defineProperty2.default)(this, "isSelected", null);
    }

    /**
     * Is this column editable
     *
     * @protected
     * @property isColumnEditable
     * @type boolean
     * @default false
     */
    get isColumnEditable() {
      let isEditable = this.isEditRow;

      if (isEditable === true) {
        let columnEditable = this.column.editable;

        if (typeof columnEditable === 'function') {
          isEditable = columnEditable() || false;
        } else if (columnEditable === false) {
          isEditable = false;
        }
      }

      return isEditable;
    }
    /**
     * Given the mode for a cell (Edit or not) will determine which component to render
     *
     * @property componentToRender
     * @default null
     * @type ?string
     * @protected
     */


    get componentToRender() {
      if (Ember.isNone(this.column.propertyName)) {
        return undefined;
      }

      let editComponent = undefined;

      if (this.isColumnEditable) {
        editComponent = this.column.componentForEdit;
        editComponent = Ember.isPresent(editComponent) ? editComponent : this.themeInstance.cellContentEditComponent;
      }

      let cellDisplayComponent = this.column.component || this.themeInstance.cellContentDisplayComponent;
      return editComponent || cellDisplayComponent;
    }

    click(e) {
      if (this.isEditRow) {
        e.stopPropagation();
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "columnClassName", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isColumnEditable", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isColumnEditable"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "componentToRender", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "componentToRender"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = CellComponent;
});