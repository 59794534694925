define("ember-models-table/components/models-table/cell-edit-toggle", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/cell-edit-toggle"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _cellEditToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  /**
   * Component for cells used as toggle for edit-mode.
   *
   * Properties and event-handlers from [models-table/cell](Components.ModelsTableCell.html) are bound here
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @columnComponents={{hash
   *     editRow=(component
   *       "models-table/cell-edit-toggle"
   *       saveRowAction=(action "onSaveRow")
   *       cancelRowAction=(action "onCancelRow")
   *   )}}
   * />
   * ```
   *
   * ```js
   * import Controller from '@ember/controller';
   * import {action} from '@ember/object';
   *
   * export default class InLineEditController extends Controller {
   *
   *   data = [];
   *
   *   columns = [
   *     {propertyName: 'firstName'},
   *     {propertyName: 'lastName'},
   *     {
   *       title: 'Edit',
   *       component: 'editRow',
   *       editable: false // <--- IMPORTANT
   *     }
   *   ];
   *
   *   @action
   *   onSaveRow(param) {
   *     return param.record.save();
   *   }
   *
   *   @action
   *   onCancelRow({record}) {
   *     record.rollbackAttributes();
   *     return true;
   *   }
   * }
   * ```
   *
   * @namespace Components
   * @class ModelsTableCellEditToggle
   * @extends Ember.Component
   */
  let CellEditToggleComponent = (_dec = (0, _component.layout)(_cellEditToggle.default), _dec2 = Ember.computed.alias('themeInstance.editRowButtonLabelMsg'), _dec3 = Ember.computed.alias('themeInstance.cancelRowButtonLabelMsg'), _dec4 = Ember.computed.alias('themeInstance.saveRowButtonLabelMsg'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = (_class2 = class CellEditToggleComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "editRowAction", null);
      (0, _defineProperty2.default)(this, "saveRowAction", null);
      (0, _defineProperty2.default)(this, "cancelRowAction", null);
      (0, _defineProperty2.default)(this, "record", null);
      (0, _defineProperty2.default)(this, "index", null);
      (0, _defineProperty2.default)(this, "column", null);
      (0, _defineProperty2.default)(this, "isEditRow", null);
      (0, _defineProperty2.default)(this, "groupedLength", null);
      (0, _defineProperty2.default)(this, "expandRow", null);
      (0, _defineProperty2.default)(this, "collapseRow", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "editRow", null);
      (0, _defineProperty2.default)(this, "saveRow", null);
      (0, _defineProperty2.default)(this, "cancelEditRow", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "isExpanded", null);
      (0, _defineProperty2.default)(this, "isSelected", null);
      (0, _defineProperty2.default)(this, "isColumnEditable", false);
      (0, _initializerDefineProperty2.default)(this, "editButtonLabel", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "cancelButtonLabel", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "saveButtonLabel", _descriptor3, this);
    }

    click(event) {
      event.stopPropagation();
    }
    /**
     * Fires when "Save" button is clicked. Edit-mode for row is turned off
     *
     * @event saveClicked
     * @protected
     */


    saveClicked() {
      let actionResult = true;

      if (this.saveRowAction) {
        actionResult = this.saveRowAction({
          record: this.record
        });
      }

      Ember.RSVP.resolve(actionResult).then(result => {
        if (result) {
          this.saveRow();
        }
      });
    }
    /**
     * Fires when "Edit" button is clicked. Edit-mode for row is turned on
     *
     * @event editClicked
     * @protected
     */


    editClicked() {
      let actionResult = true;

      if (this.editRowAction) {
        actionResult = this.editRowAction({
          record: this.record
        });
      }

      Ember.RSVP.resolve(actionResult).then(result => {
        if (result) {
          this.editRow();
        }
      });
    }
    /**
     * Fires when "Cancel Edit" button is clicked. Edit-mode for row is turned off
     *
     * @event cancelClicked
     * @protected
     */


    cancelClicked() {
      let actionResult = true;

      if (this.cancelRowAction) {
        actionResult = this.cancelRowAction({
          record: this.record
        });
      }

      Ember.RSVP.resolve(actionResult).then(result => {
        if (result) {
          this.cancelEditRow();
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editButtonLabel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "cancelButtonLabel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "saveButtonLabel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "saveClicked", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "saveClicked"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editClicked", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "editClicked"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "cancelClicked", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "cancelClicked"), _class2.prototype)), _class2)) || _class);
  _exports.default = CellEditToggleComponent;
});