define("ember-models-table/templates/components/models-table/row-group-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OhaedNvv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[9,\"a\",true],[12,\"href\",\"#\",null],[13,\"onclick\",[31,68,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"doToggleGroupedRows\"]]],null],null],[10],[1,0,0,0,[27,[24,0],[\"groupedValue\"]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/row-group-toggle.hbs"
    }
  });

  _exports.default = _default;
});