define("ember-models-table/templates/components/models-table/no-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SkwhqOUB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"td\",true],[13,\"colspan\",[27,[24,0],[\"realColumnsCount\"]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,96,9,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"noDataToShowMsg\"]]],null]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"html-safe\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/no-data.hbs"
    }
  });

  _exports.default = _default;
});