define("ember-models-table/components/models-table/group-summary-row", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/group-summary-row"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _groupSummaryRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;

  /**
   * @class ModelsTableGroupSummaryRow
   * @namespace Components
   * @extends Ember.Component
   */
  let GroupSummaryRowComponent = (_dec = (0, _component.layout)(_groupSummaryRow.default), _dec2 = (0, _component.classNames)('group-summary-row'), _dec3 = (0, _component.tagName)('tr'), _dec4 = Ember.computed.intersect('selectedItems', 'groupedItems'), _dec5 = Ember.computed.intersect('expandedItems', 'groupedItems'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class GroupSummaryRowComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "groupedItems", null);
      (0, _defineProperty2.default)(this, "visibleGroupedItems", null);
      (0, _initializerDefineProperty2.default)(this, "selectedGroupedItems", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "expandedGroupedItems", _descriptor2, this);
      (0, _defineProperty2.default)(this, "themeInstance", null);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectedGroupedItems", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "expandedGroupedItems", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class) || _class);
  _exports.default = GroupSummaryRowComponent;
});