define("ember-models-table/helpers/stringify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringify = stringify;

  function stringify(_ref
  /*, hash*/
  ) {
    let [val] = _ref;
    return JSON.stringify(val);
  }

  var _default = Ember.Helper.helper(stringify);

  _exports.default = _default;
});