define("ember-models-table/components/models-table/themes/bootstrap4/data-group-by-select", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/themes/bootstrap4/data-group-by-select", "ember-models-table/components/models-table/data-group-by-select"], function (_exports, _component, _dataGroupBySelect, _dataGroupBySelect2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class Bs4ModelsTableDataGroupBySelect
   * @extends Components.ModelsTableDataGroupBySelect
   * @namespace Components
   */
  let Bs4ModelsTableDataGroupBySelectComponent = (_dec = (0, _component.layout)(_dataGroupBySelect.default), _dec(_class = class Bs4ModelsTableDataGroupBySelectComponent extends _dataGroupBySelect2.default {}) || _class);
  _exports.default = Bs4ModelsTableDataGroupBySelectComponent;
});