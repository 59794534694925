define("ember-models-table/templates/components/models-table/row-select-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gortro24",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"button\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"buttonLink\"]],null],[13,\"onclick\",[31,81,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"doClickOnRow\"]],[27,[24,0],[\"index\"]],[27,[24,0],[\"record\"]]],null],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"i\",true],[13,\"class\",[31,142,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"isSelected\"]],[27,[24,0],[\"themeInstance\",\"selectRowIcon\"]],[27,[24,0],[\"themeInstance\",\"deselectRowIcon\"]]],null],null],[10],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[16,1,null]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/row-select-checkbox.hbs"
    }
  });

  _exports.default = _default;
});