define("ember-models-table/components/models-table/themes/ember-paper/global-filter", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/components/models-table/global-filter", "ember-models-table/templates/components/models-table/themes/ember-paper/global-filter"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _globalFilter, _globalFilter2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  /**
   * @class EpModelsTableGlobalFilter
   * @namespace Components
   * @extends Components.ModelsTableGlobalFilter
   */
  let EpModelsTableGlobalFilterComponent = (_dec = (0, _component.layout)(_globalFilter2.default), _dec2 = (0, _component.classNames)('globalSearch'), _dec3 = Ember.computed.alias('themeInstance.globalFilterWrapper'), _dec(_class = _dec2(_class = (_class2 = class EpModelsTableGlobalFilterComponent extends _globalFilter.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "globalFilterWrapper", _descriptor, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "globalFilterWrapper", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = EpModelsTableGlobalFilterComponent;
});