define("ember-power-select/templates/components/power-select/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aDh6h/g5",
    "block": "{\"symbols\":[\"@placeholder\",\"@placeholderComponent\",\"@select\",\"&default\",\"@extra\",\"@selectedItemComponent\",\"@allowClear\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[27,[24,3],[\"selected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[27,[24,6],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,64,9,[27,[26,0,\"CallHead\"],[]],[[27,[24,6],[]]],[[\"extra\",\"option\",\"select\"],[[31,104,8,[27,[26,3,\"CallHead\"],[]],[[27,[24,5],[]]],null],[31,129,8,[27,[26,3,\"CallHead\"],[]],[[27,[24,3],[\"selected\"]]],null],[31,164,8,[27,[26,3,\"CallHead\"],[]],[[27,[24,3],[]]],null]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"span\",true],[12,\"class\",\"ember-power-select-selected-item\",null],[10],[16,4,[[27,[24,3],[\"selected\"]],[27,[26,2,\"Expression\"],[]]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[5,[27,[26,4,\"BlockHead\"],[]],[[31,306,3,[27,[26,6,\"CallHead\"],[]],[[27,[24,7],[]],[31,323,3,[27,[26,5,\"CallHead\"],[]],[[27,[24,3],[\"disabled\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"span\",false],[23,\"class\",\"ember-power-select-clear-btn\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[\"mousedown\",[27,[24,0],[\"clear\"]]],null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[\"touchstart\",[27,[24,0],[\"clear\"]]],null],[10],[1,1,0,0,\"×\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,494,9,[27,[26,0,\"CallHead\"],[]],[[27,[24,2],[]]],[[\"placeholder\"],[[27,[24,1],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[9,\"span\",true],[12,\"class\",\"ember-power-select-status-icon\",null],[10],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"on\",\"select\",\"readonly\",\"if\",\"not\",\"and\"]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/trigger.hbs"
    }
  });

  _exports.default = _default;
});