define("ember-models-table/components/models-table/row-expand", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-expand"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _rowExpand) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;

  /**
   * Extra row with additional information for original row. Component used within [models-table/table-body](Components.ModelsTableTableBody.html).
   *
   * Row expand is a table-row with a single cell. This cell has a `colspan` equal to the shown columns count. You should provide an [ModelsTable.expandedRowComponent](Components.ModelsTable.html#property_expandedRowComponent). It will be rendered inside ModelsTableRowExpand.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#each MT.visibleContent as |record index|}}
   *         <Body.Row @record={{record}} @index={{index}} />
   *         {{#if (exists-in MT.expandedItems record)}}
   *           <Body.RowExpand @record={{record}} @index={{index}} />
   *         {{/if}}
   *       {{/each}}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @class ModelsTableRowExpand
   * @namespace Components
   * @extends Ember.Component
   */
  let RowExpandComponent = (_dec = (0, _component.layout)(_rowExpand.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = (0, _component.classNames)('expand-row'), _dec4 = Ember.computed('index'), _dec5 = (0, _component.className)('selected-expand'), _dec6 = Ember.computed('selectedItems.[]', 'record'), _dec7 = Ember.computed('additionalColspan', 'visibleProcessedColumns.length'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class RowExpandComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "additionalColspan", 0);
      (0, _defineProperty2.default)(this, "index", null);
      (0, _defineProperty2.default)(this, "record", null);
      (0, _defineProperty2.default)(this, "expandedRowComponent", null);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "clickOnRow", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
    }

    /**
     * @property tagName
     * @type string
     * @default 'tr'
     */

    /**
     * @property indexedClass
     * @type string
     * @protected
     */
    get indexedClass() {
      return `expand-${this.index}`;
    }
    /**
     * @property isSelected
     * @type boolean
     * @protected
     */


    get isSelected() {
      return Ember.isArray(this.selectedItems) && this.selectedItems.includes(this.record);
    }
    /**
     * @protected
     * @property cellColspan
     * @default 0
     * @type number
     */


    get cellColspan() {
      return Number(this.additionalColspan) + Number(this.visibleProcessedColumns.length);
    }
    /**
     * Extra colspan used in the internal `td`. Useful in cases with block scope usage when some extra columns are in the table (not only `columns`)
     *
     * @property additionalColspan
     * @type number
     * @default 0
     */


    click() {
      this.clickOnRow(this.index, this.record);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "indexedClass", [_component.className, _dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "indexedClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isSelected", [_dec5, _dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isSelected"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "cellColspan", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "cellColspan"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = RowExpandComponent;
});