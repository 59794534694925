define("ember-models-table/components/models-table/page-size-select", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/page-size-select"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _pageSizeSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  /**
   * Dropdown with page size values used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Value from [ModelsTable.pageSizeOptions](Components.ModelsTable.html#property_pageSizeOptions) is used as a list of dropdown-options.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PageSizeSelect />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PageSizeSelect as |SizeSelectBlock|>
   *       <SizeSelectBlock.Select />
   *     </Footer.PageSizeSelect>
   *   </MT.Footer>
   * </ModelsTable>
   * ```
   * ModelsTablePageSizeSelect yields references to the following contextual components:
   *
   * * Select - selectbox with list of available page size options
   *
   * @class ModelsTablePageSizeSelect
   * @namespace Components
   * @extends Ember.Component
   */
  let PageSizeSelectComponent = (_dec = (0, _component.layout)(_pageSizeSelect.default), _dec2 = Ember.computed.alias('themeInstance.pageSizeWrapper'), _dec3 = Ember.computed('elementId'), _dec(_class = (_class2 = class PageSizeSelectComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "pageSizeWrapper", _descriptor, this);
      (0, _defineProperty2.default)(this, "pageSizeOptions", null);
      (0, _defineProperty2.default)(this, "pageSize", 10);
      (0, _defineProperty2.default)(this, "themeInstance", null);
    }

    /**
     * @property inputId
     * @type string
     * @private
     */
    get inputId() {
      return `${this.elementId}-page-size-select`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "pageSizeWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "inputId", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype)), _class2)) || _class);
  _exports.default = PageSizeSelectComponent;
});