define("ember-models-table/components/models-table/themes/ember-paper/cell-content-edit", ["exports", "ember-models-table/templates/components/models-table/themes/ember-paper/cell-content-edit", "@ember-decorators/component", "ember-models-table/components/models-table/cell-content-edit"], function (_exports, _cellContentEdit, _component, _cellContentEdit2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class EpModelsTableCellContentEdit
   * @namespace Components
   * @extends Components.ModelsTableCellContentEdit
   */
  let EpModelsTableCellContentEditComponent = (_dec = (0, _component.layout)(_cellContentEdit.default), _dec(_class = class EpModelsTableCellContentEditComponent extends _cellContentEdit2.default {}) || _class);
  _exports.default = EpModelsTableCellContentEditComponent;
});