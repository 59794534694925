define("ember-power-select/components/power-select/trigger", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-power-select/templates/components/power-select/trigger"], function (_exports, _applyDecoratedDescriptor2, _component, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  let Trigger = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_trigger.default), _dec3 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class Trigger extends Ember.Component {
    clear(e) {
      e.stopPropagation();
      this.select.actions.select(null);

      if (e.type === 'touchstart') {
        return false;
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clear", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "clear"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = Trigger;
});