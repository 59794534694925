define("ember-models-table/components/models-table/themes/ember-paper/pagination-numeric", ["exports", "@ember-decorators/component", "ember-models-table/components/models-table/pagination-numeric", "ember-models-table/templates/components/models-table/themes/ember-paper/pagination-numeric"], function (_exports, _component, _paginationNumeric, _paginationNumeric2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class EpModelsTablePaginationNumeric
   * @namespace Components
   * @extends Components.ModelsTablePaginationNumeric
   */
  let EpModelsTablePaginationNumericComponent = (_dec = (0, _component.layout)(_paginationNumeric2.default), _dec(_class = class EpModelsTablePaginationNumericComponent extends _paginationNumeric.default {}) || _class);
  _exports.default = EpModelsTablePaginationNumericComponent;
});