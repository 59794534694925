define("ember-models-table/components/models-table/expand-all-toggle", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-models-table/templates/components/models-table/expand-all-toggle"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _expandAllToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  /**
   * Component to expand or collapse all rows
   *
   * Usage example:
   *
   * ```js
   * const columns = [
   *   {
   *     component: 'models-table/expand-toggle',
   *     componentForFilterCell: 'models-table/expand-all-toggle',
   *     mayBeHidden: false
   *   },
   *   {propertyName: 'firstName'},
   *   {propertyName: 'lastName'}
   * ];
   *
   * const data = [ ... ];
   * ```
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @multipleExpand={{true}}
   *   @expandedRowComponent={{component "path/to/your-component"}}
   * />
   * ```
   *
   * @class ModelsTableExpandAllToggle
   * @extends Ember.Component
   * @namespace Components
   */
  let ExpandAllToggleComponent = (_dec = (0, _component.layout)(_expandAllToggle.default), _dec2 = Ember._action, _dec3 = Ember._action, _dec(_class = (_class2 = class ExpandAllToggleComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "column", null);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "toggleAllSelection", null);
    }

    /**
     * @event doCollapseAllRows
     * @protected
     */
    doCollapseAllRows() {
      this.collapseAllRows();
      return false;
    }
    /**
     * @event doExpandAllRows
     * @protected
     */


    doExpandAllRows() {
      this.expandAllRows();
      return false;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doCollapseAllRows", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "doCollapseAllRows"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doExpandAllRows", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "doExpandAllRows"), _class2.prototype)), _class2)) || _class);
  _exports.default = ExpandAllToggleComponent;
});