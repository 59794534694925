define("ember-models-table/components/models-table/themes/ember-paper/expand-toggle", ["exports", "ember-models-table/templates/components/models-table/themes/ember-paper/expand-toggle", "@ember-decorators/component", "ember-models-table/components/models-table/expand-toggle"], function (_exports, _expandToggle, _component, _expandToggle2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class EpModelsTableExpandToggle
   * @namespace Components
   * @extends Components.ModelsTableExpandToggle
   */
  let EpModelsTableExpandToggleComponent = (_dec = (0, _component.layout)(_expandToggle.default), _dec(_class = class EpModelsTableExpandToggleComponent extends _expandToggle2.default {}) || _class);
  _exports.default = EpModelsTableExpandToggleComponent;
});