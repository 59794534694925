define("ember-power-select/components/power-select/before-options", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-power-select/templates/components/power-select/before-options"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _beforeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  let BeforeOptions = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_beforeOptions.default), _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class BeforeOptions extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "autofocus", true);
    }

    willDestroyElement() {
      this._super(...arguments);

      if (this.searchEnabled) {
        Ember.run.scheduleOnce('actions', this, this.select.actions.search, '');
      }
    }

    handleKeydown(e) {
      if (this.onKeydown(e) === false) {
        return false;
      }

      if (e.keyCode === 13) {
        this.select.actions.close(e);
      }
    }

    focusInput(el) {
      Ember.run.later(() => {
        if (this.autofocus) {
          el.focus();
        }
      }, 0);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleKeydown", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "focusInput", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "focusInput"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = BeforeOptions;
});