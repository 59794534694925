define("ember-models-table/templates/components/models-table/themes/ember-paper/expand-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U4EoLgbL",
    "block": "{\"symbols\":[\"@record\",\"@index\",\"&default\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,0],[\"isExpanded\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,\"paper-button\",[],[[\"@onClick\",\"@iconButton\"],[[31,51,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"doCollapseRow\"]],[27,[24,2],[]],[27,[24,1],[]]],null],true]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[7,\"paper-icon\",[[14,\"class\",[27,[24,0],[\"themeInstance\",\"collapseRowIcon\"]],null]],[[\"@icon\"],[[27,[24,0],[\"themeInstance\",\"collapseRowIcon\"]]]],null],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[7,\"paper-button\",[],[[\"@onClick\",\"@iconButton\"],[[31,275,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"doExpandRow\"]],[27,[24,2],[]],[27,[24,1],[]]],null],true]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[7,\"paper-icon\",[[14,\"class\",[27,[24,0],[\"themeInstance\",\"expandRowIcon\"]],null]],[[\"@icon\"],[[27,[24,0],[\"themeInstance\",\"expandRowIcon\"]]]],null],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[16,3,null]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/expand-toggle.hbs"
    }
  });

  _exports.default = _default;
});