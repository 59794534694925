define("ember-models-table/components/models-table/summary", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/templates/components/models-table/summary", "ember-models-table/utils/fmt"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _summary, _fmt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor;

  /**
   * Summary block used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.Summary />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * References to the following properties are yielded:
   *
   * * [summary](Components.ModelsTableSummary.html#property_summary) - message like "Show xx - yy from zzz"
   *
   * @class ModelsTableSummary
   * @namespace Components
   * @extends Ember.Component
   */
  let SummaryComponent = (_dec = (0, _component.layout)(_summary.default), _dec2 = Ember.computed.alias('themeInstance.footerSummary'), _dec3 = Ember.computed('useNumericPagination', 'themeInstance.{footerSummaryNumericPagination,footerSummaryDefaultPagination}'), _dec4 = Ember.computed('firstIndex', 'lastIndex', 'msg', 'recordsCount', 'themeInstance.tableSummaryMsg'), _dec5 = Ember.computed('elementId'), _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = (_class2 = class SummaryComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "themeFooterSummaryClass", _descriptor, this);
      (0, _defineProperty2.default)(this, "firstIndex", null);
      (0, _defineProperty2.default)(this, "lastIndex", null);
      (0, _defineProperty2.default)(this, "recordsCount", null);
      (0, _defineProperty2.default)(this, "anyFilterUsed", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "clearFilters", null);
      (0, _defineProperty2.default)(this, "useNumericPagination", null);
    }

    /**
     * @property paginationTypeClass
     * @type string
     * @protected
     */
    get paginationTypeClass() {
      return this.useNumericPagination ? this.themeInstance.footerSummaryNumericPagination : this.themeInstance.footerSummaryDefaultPagination;
    }
    /**
     * Bound from [ModelsTable.firstIndex](Components.ModelsTable.html#property_firstIndex)
     *
     * @property firstIndex
     * @type number
     * @default null
     */


    /**
     * @property summary
     * @type string
     * @protected
     */
    get summary() {
      return (0, _fmt.default)(this.themeInstance.tableSummaryMsg, this.firstIndex, this.lastIndex, this.recordsCount);
    }
    /**
     * @property inputId
     * @type string
     * @protected
     */


    get inputId() {
      return `${this.elementId}-summary-input`;
    }
    /**
     * @event doClearFilters
     * @param {Event} e
     * @protected
     */


    doClearFilters(e) {
      if (e) {
        e.stopPropagation();
      }

      this.clearFilters();
    }
    /**
     * @event noop
     * @protected
     */


    noop() {}

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "themeFooterSummaryClass", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "paginationTypeClass", [_component.className, _dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "paginationTypeClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "summary", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "summary"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "inputId", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doClearFilters", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "doClearFilters"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "noop", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype)), _class2)) || _class);
  _exports.default = SummaryComponent;
});