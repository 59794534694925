define("ember-models-table/components/models-table/themes/ember-paper/cell-edit-toggle", ["exports", "ember-models-table/templates/components/models-table/themes/ember-paper/cell-edit-toggle", "@ember-decorators/component", "ember-models-table/components/models-table/cell-edit-toggle"], function (_exports, _cellEditToggle, _component, _cellEditToggle2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class EpModelsTableColumnsDropdown
   * @namespace Components
   * @extends Components.ModelsTableColumnsDropdown
   */
  let EpModelsTableCellEditToggleComponent = (_dec = (0, _component.layout)(_cellEditToggle.default), _dec(_class = class EpModelsTableCellEditToggleComponent extends _cellEditToggle2.default {}) || _class);
  _exports.default = EpModelsTableCellEditToggleComponent;
});