define("ember-models-table/themes/bootstrap4", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-models-table/themes/bootstrap3"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  /**
   * @class Bootstrap4Theme
   * @namespace Themes
   * @extends Themes.Bootstrap3Theme
   */
  let Bootstrap4Theme = (_dec = Ember.computed('buttonDefault'), (_class = class Bootstrap4Theme extends _bootstrap.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "componentsPath", 'models-table/themes/bootstrap4/');
      (0, _defineProperty2.default)(this, "table", 'table table-striped table-bordered table-condensed table-sm');
      (0, _defineProperty2.default)(this, "globalFilterWrapper", 'float-left');
      (0, _defineProperty2.default)(this, "columnsDropdown", 'dropdown-menu dropdown-menu-right');
      (0, _defineProperty2.default)(this, "columnsDropdownWrapper", 'float-right columns-dropdown dropdown');
      (0, _defineProperty2.default)(this, "columnsDropdownDivider", 'dropdown-divider');
      (0, _defineProperty2.default)(this, "buttonDefault", 'btn btn-secondary');
      (0, _defineProperty2.default)(this, "footerSummaryNumericPagination", 'col-4');
      (0, _defineProperty2.default)(this, "footerSummaryDefaultPagination", 'col-5');
      (0, _defineProperty2.default)(this, "pageSizeWrapper", 'col-2');
      (0, _defineProperty2.default)(this, "pageSizeSelectWrapper", 'float-right');
      (0, _defineProperty2.default)(this, "paginationInternalWrapper", 'btn-toolbar float-right');
      (0, _defineProperty2.default)(this, "paginationWrapperNumeric", 'col-6');
      (0, _defineProperty2.default)(this, "paginationWrapperDefault", 'col-5');
      (0, _defineProperty2.default)(this, "clearFilterIcon", 'fa fa-times form-control-feedback');
      (0, _defineProperty2.default)(this, "clearAllFiltersIcon", 'fa fa-times');
      (0, _defineProperty2.default)(this, "sortGroupedPropertyBtn", 'btn');
      (0, _defineProperty2.default)(this, "input", 'form-control');
      (0, _defineProperty2.default)(this, "inputGroup", 'input-group');
      (0, _defineProperty2.default)(this, "sortAscIcon", 'fa fa-sort-asc');
      (0, _defineProperty2.default)(this, "sortDescIcon", 'fa fa-sort-desc');
      (0, _defineProperty2.default)(this, "columnVisibleIcon", 'fa fa-check-square-o');
      (0, _defineProperty2.default)(this, "columnHiddenIcon", 'fa fa-square-o');
      (0, _defineProperty2.default)(this, "navFirstIcon", 'fa fa-angle-double-left');
      (0, _defineProperty2.default)(this, "navPrevIcon", 'fa fa-angle-left');
      (0, _defineProperty2.default)(this, "navNextIcon", 'fa fa-angle-right');
      (0, _defineProperty2.default)(this, "navLastIcon", 'fa fa-angle-double-right');
      (0, _defineProperty2.default)(this, "caretIcon", 'caret');
      (0, _defineProperty2.default)(this, "expandRowIcon", 'fa fa-plus');
      (0, _defineProperty2.default)(this, "expandAllRowsIcon", 'fa fa-plus');
      (0, _defineProperty2.default)(this, "collapseRowIcon", 'fa fa-minus');
      (0, _defineProperty2.default)(this, "collapseAllRowsIcon", 'fa fa-minus');
      (0, _defineProperty2.default)(this, "selectAllRowsIcon", 'fa fa-check-square-o');
      (0, _defineProperty2.default)(this, "deselectAllRowsIcon", 'fa fa-square-o');
      (0, _defineProperty2.default)(this, "selectSomeRowsIcon", 'fa fa-minus-square-o');
      (0, _defineProperty2.default)(this, "selectRowIcon", 'fa fa-check-square-o');
      (0, _defineProperty2.default)(this, "deselectRowIcon", 'fa fa-square-o');
    }

    /**
     * @property buttonDefaultSmall
     * @type string
     * @default 'btn btn-secondary btn-sm'
     */
    get buttonDefaultSmall() {
      return `${this.buttonDefault} btn-sm`;
    }
    /**
     * @property footerSummaryNumericPagination
     * @type string
     * @default 'col-4'
     */


  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "buttonDefaultSmall", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "buttonDefaultSmall"), _class.prototype)), _class));
  _exports.default = Bootstrap4Theme;
});