define("ember-models-table/components/models-table/table-body", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember-decorators/component", "ember-models-table/templates/components/models-table/table-body"], function (_exports, _defineProperty2, _component, _tableBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  /**
   * Table body used within [models-table/table](Components.ModelsTableTable.html).
   *
   * Component contains rows for each record, row expands (if provided) and components for situations with missing data or when all columns are hidden.
   *
   Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#if MT.allColumnsAreHidden}}
   *         <Body.ColumnsHidden />
   *       {{else}}
   *         {{#if MT.visibleContent.length}}
   *           {{#each MT.visibleContent as |record index|}}
   *             <Body.Row @record={{record}} @index={{index}} />
   *             {{#if (exists-in MT.expandedItems record)}}
   *               <Body.RowExpand @record={{record}} @index={{index}} />
   *             {{/if}}
   *           {{/each}}
   *         {{else}}
   *           <Body.NoData />
   *         {{/if}}
   *       {{/if}}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * ModelsTableTableHeader yields references to the following contextual components:
   *
   * * [models-table/columns-hidden](Components.ModelsTableColumnsHidden.html) - component used when all columns are hidden and no data items are shown. Usually it contains a simple message
   * * [models-table/row](Components.ModelsTableRow.html) - component represents each table-body row with a single record.
   * * [models-table/row-expand](Components.ModelsTableRowExpand.html) - component with extra data shown when selected row is expanded
   * * [models-table/row-grouping](Components.ModelsTableRowGrouping.html) - component with grouped property value. Used to toggle row group
   * * [models-table/no-data](Components.ModelsTableNoData.html) - component used when no data provided to the table. Usually it contains a simple message
   *
   * Check own docs for each component to get detailed info.
  
   *
   * @namespace Components
   * @class ModelsTableTableBody
   * @extends Ember.Component
   */
  let TableBodyComponent = (_dec = (0, _component.layout)(_tableBody.default), _dec2 = (0, _component.tagName)('tbody'), _dec(_class = _dec2(_class = class TableBodyComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "columnsCount", null);
      (0, _defineProperty2.default)(this, "visibleContent", null);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "expandedRowComponent", null);
      (0, _defineProperty2.default)(this, "groupingRowComponent", null);
      (0, _defineProperty2.default)(this, "groupSummaryRowComponent", null);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "allColumnsAreHidden", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "useDataGrouping", null);
      (0, _defineProperty2.default)(this, "collapsedGroupValues", null);
      (0, _defineProperty2.default)(this, "currentGroupingPropertyName", null);
      (0, _defineProperty2.default)(this, "dataGroupOptions", null);
      (0, _defineProperty2.default)(this, "groupedVisibleContentValuesOrder", null);
      (0, _defineProperty2.default)(this, "groupedVisibleContent", null);
      (0, _defineProperty2.default)(this, "groupedArrangedContent", null);
      (0, _defineProperty2.default)(this, "displayGroupedValueAs", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRows", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRowsSelection", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRowsExpands", null);
      (0, _defineProperty2.default)(this, "clickOnRow", null);
      (0, _defineProperty2.default)(this, "doubleClickOnRow", null);
      (0, _defineProperty2.default)(this, "hoverOnRow", null);
      (0, _defineProperty2.default)(this, "outRow", null);
      (0, _defineProperty2.default)(this, "expandRow", null);
      (0, _defineProperty2.default)(this, "collapseRow", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
    }

  }) || _class) || _class);
  _exports.default = TableBodyComponent;
});