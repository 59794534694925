define("ember-resolver/utils/make-dictionary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = makeDictionary;

  function makeDictionary() {
    let cache = Object.create(null);
    cache['_dict'] = null;
    delete cache['_dict'];
    return cache;
  }
});