define("ember-models-table/templates/components/models-table/themes/ember-paper/expand-all-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BZSSSOsv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"paper-button\",[],[[\"@onClick\",\"@iconButton\"],[[27,[24,0],[\"doExpandAllRows\"]],true]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n  \"],[7,\"paper-icon\",[[14,\"class\",[27,[24,0],[\"themeInstance\",\"expandAllRowsIcon\"]],null]],[[\"@icon\"],[[27,[24,0],[\"themeInstance\",\"expandAllRowsIcon\"]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[9,\"br\",true],[10],[11],[1,1,0,0,\"\\n\"],[7,\"paper-button\",[],[[\"@onClick\",\"@iconButton\"],[[27,[24,0],[\"doCollapseAllRows\"]],true]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n  \"],[7,\"paper-icon\",[[14,\"class\",[27,[24,0],[\"themeInstance\",\"collapseAllRowsIcon\"]],null]],[[\"@icon\"],[[27,[24,0],[\"themeInstance\",\"collapseAllRowsIcon\"]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[16,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/expand-all-toggle.hbs"
    }
  });

  _exports.default = _default;
});