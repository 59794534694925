define("ember-models-table/components/models-table/themes/ember-paper/data-group-by-select", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-models-table/components/models-table/data-group-by-select", "ember-models-table/templates/components/models-table/themes/ember-paper/data-group-by-select"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _dataGroupBySelect, _dataGroupBySelect2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  /**
   * @class EpModelsTableDataGroupBySelect
   * @namespace Components
   * @extends Components.ModelsTableDataGroupBySelect
   */
  let EpModelsTableDataGroupBySelectComponent = (_dec = (0, _component.layout)(_dataGroupBySelect2.default), _dec2 = Ember.computed.alias('themeInstance.dataGroupBySelectWrapper'), _dec(_class = (_class2 = class EpModelsTableDataGroupBySelectComponent extends _dataGroupBySelect.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "dataGroupBySelectWrapper", _descriptor, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dataGroupBySelectWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = EpModelsTableDataGroupBySelectComponent;
});