define("ember-models-table/themes/ember-bootstrap-v4", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-models-table/themes/bootstrap4"], function (_exports, _defineProperty2, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class EmberBootstrap4Theme
   * @namespace Themes
   * @extends Themes.Bootstrap4Theme
   */
  class EmberBootstrap4Theme extends _bootstrap.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "componentsPath", 'models-table/themes/ember-bootstrap-v4/');
      (0, _defineProperty2.default)(this, "sortGroupedPropertyBtn", 'btn btn-link');
    }

  }

  _exports.default = EmberBootstrap4Theme;
});