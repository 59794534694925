define("ember-models-table/themes/bootstrap3", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-models-table/themes/default"], function (_exports, _defineProperty2, _default) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Bootstrap3Theme
   * @namespace Themes
   * @extends Themes.DefaultTheme
   */
  class Bootstrap3Theme extends _default.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "componentsPath", 'models-table/themes/bootstrap3/');
      (0, _defineProperty2.default)(this, "table", 'table table-striped table-bordered table-condensed');
      (0, _defineProperty2.default)(this, "buttonsGroup", 'btn-group');
      (0, _defineProperty2.default)(this, "globalFilterWrapper", 'pull-left');
      (0, _defineProperty2.default)(this, "sortGroupedPropertyBtn", 'btn btn-default');
      (0, _defineProperty2.default)(this, "columnsDropdownWrapper", 'pull-right columns-dropdown');
      (0, _defineProperty2.default)(this, "columnsDropdown", 'dropdown-menu pull-right');
      (0, _defineProperty2.default)(this, "columnsDropdownDivider", 'divider');
      (0, _defineProperty2.default)(this, "dataGroupBySelectWrapper", 'data-group-by-wrapper pull-left');
      (0, _defineProperty2.default)(this, "footerSummaryNumericPagination", 'col-md-4 col-sm-4 col-xs-4');
      (0, _defineProperty2.default)(this, "footerSummaryDefaultPagination", 'col-md-5 col-sm-5 col-xs-5');
      (0, _defineProperty2.default)(this, "pageSizeWrapper", 'col-md-2 col-sm-2 col-xs-2');
      (0, _defineProperty2.default)(this, "pageSizeSelectWrapper", 'pull-left');
      (0, _defineProperty2.default)(this, "currentPageSizeSelectWrapper", 'pull-right');
      (0, _defineProperty2.default)(this, "paginationInternalWrapper", 'btn-toolbar pull-right');
      (0, _defineProperty2.default)(this, "paginationWrapperNumeric", 'col-md-6 col-sm-6 col-xs-6');
      (0, _defineProperty2.default)(this, "paginationWrapperDefault", 'col-md-5 col-sm-5 col-xs-5');
      (0, _defineProperty2.default)(this, "paginationBlock", 'btn-group');
      (0, _defineProperty2.default)(this, "paginationNumericItemActive", 'active');
      (0, _defineProperty2.default)(this, "buttonDefault", 'btn btn-default');
      (0, _defineProperty2.default)(this, "buttonLink", 'btn btn-link');
      (0, _defineProperty2.default)(this, "form", 'form-inline');
      (0, _defineProperty2.default)(this, "formElementWrapper", 'form-group');
      (0, _defineProperty2.default)(this, "input", 'form-control');
      (0, _defineProperty2.default)(this, "select", '');
      (0, _defineProperty2.default)(this, "tfooterWrapper", 'table-footer clearfix');
      (0, _defineProperty2.default)(this, "tfooterInternalWrapper", 'row');
      (0, _defineProperty2.default)(this, "clearFilterIcon", 'glyphicon glyphicon-remove-sign form-control-feedback');
      (0, _defineProperty2.default)(this, "clearAllFiltersIcon", 'glyphicon glyphicon-remove-circle');
      (0, _defineProperty2.default)(this, "sortAscIcon", 'glyphicon glyphicon-triangle-top');
      (0, _defineProperty2.default)(this, "sortDescIcon", 'glyphicon glyphicon-triangle-bottom');
      (0, _defineProperty2.default)(this, "columnVisibleIcon", 'glyphicon glyphicon-check');
      (0, _defineProperty2.default)(this, "columnHiddenIcon", 'glyphicon glyphicon-unchecked');
      (0, _defineProperty2.default)(this, "navFirstIcon", 'glyphicon glyphicon-chevron-left');
      (0, _defineProperty2.default)(this, "navPrevIcon", 'glyphicon glyphicon-menu-left');
      (0, _defineProperty2.default)(this, "navNextIcon", 'glyphicon glyphicon-menu-right');
      (0, _defineProperty2.default)(this, "navLastIcon", 'glyphicon glyphicon-chevron-right');
      (0, _defineProperty2.default)(this, "caretIcon", 'caret');
      (0, _defineProperty2.default)(this, "expandRowIcon", 'glyphicon glyphicon-plus');
      (0, _defineProperty2.default)(this, "expandAllRowsIcon", 'glyphicon glyphicon-plus');
      (0, _defineProperty2.default)(this, "collapseRowIcon", 'glyphicon glyphicon-minus');
      (0, _defineProperty2.default)(this, "collapseAllRowsIcon", 'glyphicon glyphicon-minus');
      (0, _defineProperty2.default)(this, "selectSomeRowsIcon", 'glyphicon glyphicon-minus');
      (0, _defineProperty2.default)(this, "selectAllRowsIcon", 'glyphicon glyphicon-check');
      (0, _defineProperty2.default)(this, "deselectAllRowsIcon", 'glyphicon glyphicon-unchecked');
      (0, _defineProperty2.default)(this, "selectRowIcon", 'glyphicon glyphicon-check');
      (0, _defineProperty2.default)(this, "deselectRowIcon", 'glyphicon glyphicon-unchecked');
      (0, _defineProperty2.default)(this, "editRowButton", 'btn btn-default');
      (0, _defineProperty2.default)(this, "saveRowButton", 'btn btn-default');
      (0, _defineProperty2.default)(this, "cancelRowButton", 'btn btn-default');
    }

  }

  _exports.default = Bootstrap3Theme;
});