define("ember-models-table/components/models-table/row-group-toggle", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-group-toggle"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _rowGroupToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  /**
   * Component is used to toggle rows group visibility
   *
   * Don't override this component. Use [groupingRowComponent](Components.ModelsTable.html#property_groupingRowComponent) (it may extend this one)
   *
   * @namespace Components
   * @class ModelsTableRowGroupToggle
   * @extends Ember.Component
   */
  let RowGroupToggleComponent = (_dec = (0, _component.layout)(_rowGroupToggle.default), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = (_class2 = class RowGroupToggleComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "stopEventsPropagation", true);
      (0, _defineProperty2.default)(this, "groupIsCollapsed", null);
      (0, _defineProperty2.default)(this, "groupedValue", null);
      (0, _defineProperty2.default)(this, "groupedItems", null);
      (0, _defineProperty2.default)(this, "visibleGroupedItems", null);
      (0, _defineProperty2.default)(this, "selectedGroupedItems", null);
      (0, _defineProperty2.default)(this, "expandedGroupedItems", null);
      (0, _defineProperty2.default)(this, "currentGroupingPropertyName", null);
      (0, _defineProperty2.default)(this, "displayGroupedValueAs", null);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRows", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRowsSelection", null);
      (0, _defineProperty2.default)(this, "toggleGroupedRowsExpands", null);
    }

    /**
     * Calls passed `toggleGroupedRows`
     *
     * @event toggleGroupedRows
     */
    doToggleGroupedRows() {
      this.toggleGroupedRows(this.groupedValue);
      return !this.stopEventsPropagation;
    }
    /**
     * Calls passed `toggleGroupedRowsSelection`
     *
     * @event toggleGroupedRowsSelection
     */


    doToggleGroupedRowsSelection() {
      this.toggleGroupedRowsSelection(this.groupedValue);
      return !this.stopEventsPropagation;
    }
    /**
     * Calls passed `toggleGroupedRowsExpands`
     *
     * @event toggleGroupedRowsExpands
     */


    doToggleGroupedRowsExpands() {
      this.toggleGroupedRowsExpands(this.groupedValue);
      return !this.stopEventsPropagation;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doToggleGroupedRows", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleGroupedRows"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doToggleGroupedRowsSelection", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleGroupedRowsSelection"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doToggleGroupedRowsExpands", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleGroupedRowsExpands"), _class2.prototype)), _class2)) || _class);
  _exports.default = RowGroupToggleComponent;
});