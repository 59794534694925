define("ember-models-table/components/models-table/table-footer", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-models-table/templates/components/models-table/table-footer"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _tableFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  /**
   * Table footer used within [models-table/table](Components.ModelsTableTable.html).
   *
   * By default it's an empty component. It should be used with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Footer as |Footer|>
   *       {{! ... }}
   *     </Table.Footer>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example 2:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Footer as |Footer|>
   *        <tr>
   *          <td colspan={{if Footer.shouldAddExtraColumn  (inc MT.visibleProcessedColumns.length) MT.visibleProcessedColumns.length}}>
   *            {{! "inc" is a helper from `ember-composable-helpers` }}
   *            Some custom summary for table can be shown in the <code>tfoot</code>
   *          </td>
   *        </tr>
   *      </Table.Footer>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * References to the following properties are yielded:
   *
   * * [shouldAddExtraColumn](Components.ModelsTableTableFooter.html#property_shouldAddExtraColumn) - determines if extra column should be added to the row in the `tfoot`. It happens when rows grouping is used and extra column with group values exists
   *
   * @class ModelsTableTableFooter
   * @extends Ember.Component
   * @namespace Components
   */
  let TableFooterComponent = (_dec = (0, _component.layout)(_tableFooter.default), _dec2 = (0, _component.tagName)('tfoot'), _dec3 = Ember.computed('displayGroupedValueAs', 'useDataGrouping', 'visibleProcessedColumns.[]'), _dec(_class = _dec2(_class = (_class2 = class TableFooterComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "visibleProcessedColumns", null);
      (0, _defineProperty2.default)(this, "displayGroupedValueAs", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "useDataGrouping", null);
      (0, _defineProperty2.default)(this, "goToPage", null);
      (0, _defineProperty2.default)(this, "clearFilters", null);
      (0, _defineProperty2.default)(this, "expandRow", null);
      (0, _defineProperty2.default)(this, "collapseRow", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
    }

    /**
     * @property shouldAddExtraColumn
     * @type boolean
     * @default false
     * @protected
     */
    get shouldAddExtraColumn() {
      return this.displayGroupedValueAs === 'column' && this.useDataGrouping && !!this.visibleProcessedColumns.length;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "shouldAddExtraColumn", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldAddExtraColumn"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = TableFooterComponent;
});