define("ember-models-table/templates/components/models-table/themes/ember-paper/cell-edit-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VGicGbm+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,0],[\"isEditRow\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[7,\"paper-button\",[[14,\"class\",[31,52,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonDefault\"]],\" \",[27,[24,0],[\"themeInstance\",\"cancelRowButton\"]]],null],null]],[[\"@raised\",\"@onClick\"],[true,[27,[24,0],[\"cancelClicked\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[24,0],[\"cancelButtonLabel\"]]],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"],[7,\"paper-button\",[[14,\"class\",[31,272,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonDefault\"]],\" \",[27,[24,0],[\"themeInstance\",\"saveRowButton\"]]],null],null]],[[\"@raised\",\"@onClick\"],[true,[27,[24,0],[\"saveClicked\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[24,0],[\"saveButtonLabel\"]]],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[7,\"paper-button\",[[14,\"class\",[31,496,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"themeInstance\",\"buttonDefault\"]],\" \",[27,[24,0],[\"themeInstance\",\"editRowButton\"]]],null],null]],[[\"@raised\",\"@onClick\"],[true,[27,[24,0],[\"editClicked\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[24,0],[\"editButtonLabel\"]]],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[16,1,null]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/cell-edit-toggle.hbs"
    }
  });

  _exports.default = _default;
});