define("ember-models-table/templates/components/models-table/themes/ember-bootstrap-v4/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZbwJfd0x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,[[31,37,4,[27,[26,3,\"CallHead\"],[]],null,[[\"summary\"],[[27,[24,0],[\"summary\"]]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[7,\"bs-form\",[],[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"inline\",[27,[24,0],[]],[27,[24,0],[\"noop\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[9,\"label\",true],[10],[1,0,0,0,[27,[24,0],[\"summary\"]]],[11],[1,1,0,0,\"\\n    \"],[7,\"bs-button\",[[14,\"class\",[31,248,6,[27,[26,0,\"CallHead\"],[]],[\"clearFilters border-0 \",[27,[24,0],[\"themeInstance\",\"buttonLink\"]]],null],null],[14,\"disabled\",[31,330,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"anyFilterUsed\"]],\"disabled\"],null],null]],[[\"@outline\",\"@onClick\"],[true,[31,412,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"doClearFilters\"]]],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n      \"],[9,\"i\",true],[13,\"class\",[27,[24,0],[\"themeInstance\",\"clearAllFiltersIcon\"]],null],[10],[11],[1,1,0,0,\"\\n      \"],[9,\"span\",true],[12,\"class\",\"emt-sr-only\",null],[10],[1,0,0,0,[27,[24,0],[\"themeInstance\",\"clearAllFiltersMsg\"]]],[11],[1,1,0,0,\"\\n    \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"unless\",\"fn\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v4/summary.hbs"
    }
  });

  _exports.default = _default;
});