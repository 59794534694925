define("ember-models-table/components/models-table/no-data", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-models-table/templates/components/models-table/no-data"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _noData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  /**
   * Table row shown when no data provided to the ModelsTable. Component used within [models-table/table-body](Components.ModelsTableTableBody.html).
   *
   * This component is a table-row with a single cell. This cell has a `colspan` equal to the shown columns count.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#if Body.visibleContent.length}}
   *         {{! ... }}
   *       {{else}}
   *         <Body.NoData />
   *       {{/if}}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#if Body.visibleContent.length}}
   *         {{! ... }}
   *       {{else}}
   *         <Body.NoData as |ND|>
   *           Any message here
   *         </Body.NoData>
   *       {{/if}}
   *     </table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @class ModelsTableNoData
   * @namespace Components
   * @extends Ember.Component
   */
  let NoDataComponent = (_dec = (0, _component.layout)(_noData.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = Ember.computed('columnsCount', 'displayGroupedValueAs'), _dec(_class = _dec2(_class = (_class2 = class NoDataComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "columnsCount", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
    }

    /**
     * @property tagName
     * @type string
     * @default 'tr'
     */

    /**
     * @property realColumnsCount
     * @type number
     * @protected
     */
    get realColumnsCount() {
      return this.columnsCount + (this.displayGroupedValueAs === 'column' ? 1 : 0);
    }
    /**
     * Equal to [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns).length
     *
     * @property columnsCount
     * @type number
     * @default null
     */


  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "realColumnsCount", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "realColumnsCount"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = NoDataComponent;
});