define("ember-models-table/components/models-table/row-select-checkbox", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-select-checkbox"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _rowSelectCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  /**
   * Component with checkbox to select or deselect any single row
   *
   * Usage example:
   *
   * ```js
   * const columns = [
   *   {
   *     component: 'models-table/row-select-checkbox',
   *     useFilter: false,
   *     mayBeHidden: false,
   *     componentForSortCell: 'models-table/row-select-all-checkbox'
   *   },
   *   {propertyName: 'firstName'},
   *   {propertyName: 'lastName'}
   * ];
   * const data = [ ... ];
   * ```
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @multipleSelect={{true}}
   * />
   * ```
   *
   * @class ModelsTableRowSelectCheckbox
   * @extends Ember.Component
   * @namespace Components
   */
  let RowSelectCheckboxComponent = (_dec = (0, _component.layout)(_rowSelectCheckbox.default), _dec2 = Ember._action, _dec(_class = (_class2 = class RowSelectCheckboxComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "column", null);
      (0, _defineProperty2.default)(this, "selectedItems", null);
      (0, _defineProperty2.default)(this, "expandedItems", null);
      (0, _defineProperty2.default)(this, "data", null);
      (0, _defineProperty2.default)(this, "themeInstance", null);
      (0, _defineProperty2.default)(this, "expandAllRows", null);
      (0, _defineProperty2.default)(this, "toggleAllSelection", null);
      (0, _defineProperty2.default)(this, "collapseAllRows", null);
      (0, _defineProperty2.default)(this, "clickOnRow", null);
    }

    /**
     * @event doClickOnRow
     * @protected
     * @param {number} index
     * @param {object} record
     * @param {Event} e
     */
    doClickOnRow(index, record, e) {
      this.clickOnRow(index, record);
      Ember.tryInvoke(e, 'stopPropagation');
      return false;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doClickOnRow", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "doClickOnRow"), _class2.prototype)), _class2)) || _class);
  _exports.default = RowSelectCheckboxComponent;
});